<template>
  <v-sheet class="ma-8 mt-2 pa-4 rounded-lg">
    <v-card-text>
      <FormulateForm #default="{ isLoading }" @submit="submitForm">
        <FormulateInput
            label="Formulier naam"
            name="form_name"
            v-model="form_name"
            validation="required"
            :validation-messages="{
          required: 'Formulier naam is verplicht.'
        }"
        />
        <FormulateInput
            label="Formuliertype"
            type="select"
            required
            :options="{'default': 'Standaard', 'workflow': 'Workflow met fases'}"
            v-model="form_type"
            class="mb-0"
        />
        <ul class="text-caption text--secondary mb-5">
          <li>Standaard: Het formulier door één gebruiker ingevult.</li>
          <li>Workflow met fases: Het formulier kan deels ingevuld worden, om vervolgens doorgestuurd te worden naar een andere gebruiker om hem verder in te vullen.</li>
        </ul>
        <FormulateInput
            label="Map"
            type="select"
            :options="folder_options"
            name="form_folder"
            v-model="form_folder"
            validation="required"
            error-behavior="live"
        />
        <FormulateInput
            input-class="v-btn v-size--default primary"
            type="submit"
            name="submit"
            :disabled="isLoading"
            :label="isLoading ? 'Laden...' : 'Formulier aanmaken'"
        />
      </FormulateForm>
    </v-card-text>
  </v-sheet>
</template>

<script>
import {mapGetters} from "vuex";
import folderHelpers from "@/mixins/folderHelpers";

export default {
  name: "CreateForm",
  mixins: [
      folderHelpers,
  ],
  data () {
    return {
      template: '',
      form_name: '',
      form_folder: '',
      form_type: 'default',
      folder_options: {},
      isLoading: false,
    }
  },
  async mounted () {
    this.isLoading = true;
    this.folder_options = await this.getFolderOptions(this.CurrentClient);
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({CurrentClient: "CurrentClient"}),
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm() {
      // Set schema based on the chosen template
      let schema = [];
      try {
        await this.$store.dispatch('createForm', {form_name: this.form_name, folder_id: this.form_folder, form_type: this.form_type, schema: schema});
        this.$toast.success("Formulier " + this.form_name + " is aangemaakt.");
      } catch (error) {
        this.$toast.error("Het formulier kon niet worden aangemaakt");
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

</style>