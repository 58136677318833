<template>
  <div>

    <v-progress-linear
        indeterminate
        v-if="loading"
    ></v-progress-linear>

    <template v-if="!loading">
      <template v-if="copyClientOptions.length > 1">
        <FormulateInput
            type="checkbox"
            v-model="copyToSameClient"
            label="Kopie hoort bij dezelfde klant"
        />
        <template v-if="!copyToSameClient">
          <v-alert
              color="warning"
              dark
              dense
              elevation="2"
          >Let op: Bij een kopie naar een andere klant worden de map en de toegewezen gebruikers niet meegenomen!</v-alert>
          <FormulateInput
              label="Klant"
              type="select"
              v-model="copyNewClientId"
              v-if="!copyToSameClient"
              :options="copyClientOptions"
          />

          <v-progress-linear indeterminate v-if="foldersLoading"></v-progress-linear>

          <FormulateInput
              label="Map"
              type="select"
              required
              :disabled="foldersLoading"
              :options="folderOptions"
              v-if="!copyToSameClient"
              v-model="copyToFolder" />

        </template>
      </template>
      <v-btn
          class="my-2"
          depressed
          color="primary"
          @click="duplicateForm"
          min-width="210"
      >Formulier kopiëren</v-btn>
    </template>

  </div>
</template>

<script>
import eformConditionals from "@/mixins/eformConditionals";
import userHelpers from "@/mixins/userHelpers";
import {mapGetters} from "vuex";
import eformHelpers from "../../mixins/eformHelpers";
import _ from "lodash";
import folderHelpers from "@/mixins/folderHelpers";

export default {
  name: "FormDuplicate",
  components: {
  },
  mixins: [
    eformConditionals,
    userHelpers,
    eformHelpers,
    folderHelpers,
  ],
  props: {
    myForm: null,
  },
  data() {
    return {
      loading: false,
      copyToSameClient: true,
      copyClientOptions: [],
      copyNewClientId: null,
      copyToFolder: null,
      folderOptions: {},
      foldersLoading: false,
    }
  },
  computed: {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient"}),
  },
  watch: {
    copyNewClientId: async function () {
      this.foldersLoading = true;
      this.folderOptions = await this.getFolderOptions(this.copyNewClientId);
      // Set the first folder as default
      if (!_.isEmpty(this.folderOptions)) {
        this.copyToFolder = Object.keys(this.folderOptions)[0];
      }
      this.foldersLoading = false;
    }
  },
  mounted () {
    this.setClientOptions();
  },
  methods: {
    setClientOptions() {
      const clientArray = [];
      if (this.User.clients) {
        for (let i = 0; i < this.User.clients.length; i++) {
          clientArray.push({'value': this.User.clients[i].id, 'label': this.User.clients[i].name});
        }
      }

      if (!_.isEmpty(this.myForm.client_id)) {
        this.copyNewClientId = this.myForm.client_id;
      }
      this.copyClientOptions = clientArray;
    },
    async duplicateForm() {
      try {
        this.loading = true;
        let clientIdChanged = false;
        const oldFormName = _.clone(this.myForm.form_name);
        const oldForm = _.cloneDeep(this.myForm);

        // Always remove all assigned users and groups
        delete oldForm.users;

        if (!this.copyToSameClient && !_.isNull(this.copyNewClientId) && this.copyNewClientId !== oldForm.client_id) {
          oldForm.client_id = this.copyNewClientId
          clientIdChanged = true;
          // Set new folder id
          oldForm.folder_id = this.copyToFolder;
        }

        const newFormId = await this.$store.dispatch('createDuplicateFromForm', {oldForm: oldForm});

        if (clientIdChanged) {
          await this.$store.commit('setCurrentClient', this.copyNewClientId)
          this.$toast.success('Kopie van formulier "'+oldFormName+'" is aangemaakt. U bent gewisseld van klant en doorgestuurd naar het nieuwe formulier.');
        }
        else {
          this.$toast.success('Kopie van formulier "'+oldFormName+'" is aangemaakt. U bent doorgestuurd naar het nieuwe formulier.');
        }

        await this.$router.push({name: 'form_view', params: {form_id: newFormId}});
      } catch (error) {
        this.$toast.error("Er kon geen kopie van het formulier worden gemaakt");
        console.log(error);
      }

      this.loading = false;

    },
  }
}
</script>

<style scoped>

</style>