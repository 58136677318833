<template>

  <div :key="guest_id">

    <template v-if="form_in_modal">
      <v-dialog persistent v-model="form_in_modal" max-width="950" scrollable :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
          <v-card-title>{{ formName }} invullen</v-card-title>
          <v-card-text class="pt-4 text--primary background-color">
            <div v-if="renderForm">
              <FormulateForm class="formulate-style-compact" v-if="!loading" @submit="submitFormHandler" @failed-validation="onValidationErrors" v-model="values" name="fillForm">
                <div v-if="!loadingForm">
                  <RegistrationSearchModal
                      class="float-right"
                      v-if="registrationSearchEnabled"
                      :form="myForm"
                      @hide-parent-form="hideForm"
                      @show-parent-form="showForm"
                      @setFormValues="setFormValues"></RegistrationSearchModal>
                </div>
                <div class="ma-md-8 mt-md-2 pa-md-4 rounded-lg">

                  <template v-if="loadingForm">
                    <v-skeleton-loader type="text@2, sentences, text@3, image, text@3"></v-skeleton-loader>
                  </template>

                  <v-alert
                      class="ma-4"
                      v-if="clientCredits < 1"
                      dense
                      prominent
                      type="warning"
                  >
                    De credits zijn op. U kunt dit formulier niet versturen.
                  </v-alert>

                  <v-container fluid>
                    <v-row v-for="item in conditionalSchema" :key="item.uuid">
                      <v-col class="py-1">
                        <eform-element
                            :element="item"
                            :disabled-prop="setDisabled(item)"
                            v-show="isVisuallyShown(item)"
                            :values="values"
                            :schema="schema"
                            :form-settings="formSettings"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </FormulateForm>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mb-2">
            <FormulateInput
                :disabled="disableSubmit"
                input-class="v-btn v-size--default success mb-0 mx-1"
                type="submit"
                color="primary"
                v-if="!accessSaveOffline"
                @click="triggerSubmitForm()"
                :label="loading ? 'Loading...' : 'Formulier versturen'"
            />
            <FormulateInput
                input-class="v-btn v-size--default warning mb-0 mx-1"
                type="submit"
                color="primary"
                v-if="accessSaveOffline"
                label="Formulier offline opslaan"
            />
            <FormulateInput
                v-if="form_in_modal"
                input-class="v-btn v-size--default error mb-0 mx-1"
                type="button"
                v-on:click.prevent="cancelFormFill"
                color="error"
                label="Annuleren"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <div v-if="!loading">
        <RegistrationSearchModal
            class="float-right"
            v-if="registrationSearchEnabled"
            :form="myForm"
            @hide-parent-form="hideForm"
            @show-parent-form="showForm"
            @setFormValues="setFormValues"></RegistrationSearchModal>
      </div>
      <div v-if="renderForm">

        <div class="pt-6 px-md-8 px-4" v-if="loading">
          <v-container fluid>
            <v-row>
              <v-col class="pa-0">
                <v-skeleton-loader type="text@2, sentences, text@3, image, text@3"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <FormulateForm class="formulate-style-compact" v-if="!loading" @submit="submitFormHandler" @failed-validation="onValidationErrors" #default="{ isLoading }" v-model="values" name="fillForm">
          <page-title :page-title="formName"></page-title>

          <div class="text-center text-lg-left">
            <div class="pl-md-8 mb-3" @click="$router.back()">
              <v-btn small outlined color="secondary">
                <v-icon left>mdi-arrow-left</v-icon> Terug
              </v-btn>
            </div>
          </div>

          <v-alert
              class="ma-4"
              v-if="clientCredits < 1"
              dense
              prominent
              type="warning"
          >
            De credits zijn op. U kunt dit formulier niet versturen.
          </v-alert>

          <div class="px-md-8 px-4">
            <v-container fluid>
              <v-row v-for="item in conditionalSchema" :key="item.uuid">
                <v-col class="pa-0">
                  <eform-element
                      :element="item"
                      :disabled-prop="setDisabled(item)"
                      v-show="isVisuallyShown(item)"
                      :values="values"
                      :schema="schema"
                      :form-settings="formSettings"
                      @subform-opened="subformOpened"
                      @subform-closed="subformClosed"
                  />
                </v-col>
              </v-row>
            </v-container>

            <template v-if="!form_in_modal">
              <v-spacer></v-spacer>
              <v-footer padless fixed inset app class="eform-footer-transparant py-sm-2">
                <v-container fluid class="mx-md-4">
                  <FormulateInput
                      :disabled="disableSubmit"
                      input-class="v-btn v-size--default success"
                      type="submit"
                      color="primary"
                      v-if="!accessSaveOffline"
                      :label="isLoading ? 'Laden...' : 'Formulier versturen'"
                  />
                  <FormulateInput
                      input-class="v-btn v-size--default warning"
                      type="submit"
                      color="primary"
                      v-if="accessSaveOffline"
                      label="Formulier offline opslaan"
                  />
                </v-container>
              </v-footer>
            </template>
          </div>
        </FormulateForm>
      </div>
    </template>

    <!-- Progress/saving modal when sending the registration -->
    <form-fill-saving-modal
        :show-modal="savingModal"
        :send-registration-phase="sendRegistrationPhase"
        :send-registration-progress="sendRegistrationProgress"
    ></form-fill-saving-modal>

    <v-dialog v-model="savingComplete" max-width="400" scrollable persistent>
      <v-card>
        <v-card-text class="pt-4 text-center">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
          <p class="mt-5">Uw registratie is verstuurd.</p>
          <p class="text-caption text--secondary">U kunt nu dit venster sluiten.</p>
          <template v-if="!form_in_modal">
<!--            <v-btn-->
<!--                depressed-->
<!--                outlined-->
<!--                color="primary"-->
<!--                v-on:click.prevent="fillFormAgain"-->
<!--                class="mx-1 my-2"-->
<!--            >-->
<!--              Nogmaals invullen-->
<!--            </v-btn>-->
          </template>

        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>

</template>
<script>
import EformElement from '@/components/EformElement';
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from 'lodash';
import axios from "axios";
import eformConditionals from "@/mixins/eformConditionals";
import formulateComplexFileData from "@/mixins/formulateComplexFileData";
import PageTitle from "@/components/Pagetitle";
import RegistrationSearchModal from "../../components/search/RegistrationSearchModal";
import eformHelpers from "../../mixins/eformHelpers";
import eformFormFillHelpers from "@/mixins/eformFormFillHelpers";
import FormFillSavingModal from "@/views/forms/FormFillSavingModal.vue";

export default {
  name: "Form",
  components: {
    FormFillSavingModal,
    RegistrationSearchModal,
    EformElement,
    PageTitle,
  },
  mixins: [
    formulateComplexFileData,
    eformConditionals,
    eformHelpers,
    eformFormFillHelpers,
  ],
  props: {
    guest_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  async mounted() {
    // Get form from state
    let form = this.getFormByGuestID(this.guest_id)
    await this.initializeForm(form);
  },
  computed: {
    ...mapGetters(["appOnline", "getFormByGuestID", "userIsFiller"]),
    accessSaveOffline() {
      if (this.appOnline === false) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["registrationCompletedByGuest"]),
    triggerSubmitForm() {
      // Trigger submit of named Vue Formulate form
      this.$formulate.submit('fillForm');
    },
    async getDataFromApi() {
      this.loadingForm = true
      // Fetch results form the API
      await axios
          .get('api/guest/form/' + this.guest_id)
          .then(response => {
            this.updateForm(response.data);
            this.loadingForm = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loadingForm = false)
    },
    // Save form online or offline
    submitFormHandler(formValues) {
      this.$toast.clear();

      // Make a copy of the formValues to remove cyclical values
      let formValuesCopy = cloneDeep(formValues);
      // Make sure file arrays have a multidimensional array (an array inside an array).
      // This ugly fix is needed because we can only set default values for a file input
      this.fileValuesConvertSingleToMultiple(this.schema, formValuesCopy)

      this.submitFormOnline(formValuesCopy)
    },
    async sendRegistrationToServer(registration) {
      this.sendRegistrationPhase = 'sending';
      // Send registration to server
      try {
        let response = await axios.post('api/guest/form/' + this.guest_id, registration, {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percentage = Math.floor((loaded * 100) / total);
            this.sendRegistrationProgress = percentage;
            // Sending completed
            if (percentage >= 100) {
              this.sendRegistrationPhase = 'server-processing';
            }
          }
        })
        // Save some field values
        await this.registrationCompletedByGuest({registration: registration});
        // Success
        return {
          success: true,
          message: 'Registratie is verstuurd.',
          status: response.status,
        };
      } catch (error) {
        // Get the response
        let responseData = error.response ? error.response.data : null;
        let errorMessage = responseData ? responseData.message : 'Er is iets mis gegaan met het versturen van de registratie.';

        return {
          success: false,
          message: errorMessage,
          status: error.response ? error.response.status : 500,
        };
      }
    },
    async onValidationErrors() {
      this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.", { timeout: false });
    },
  },
}
</script>

<style scoped lang="scss">

.v-card__actions .formulate-input {
  margin-bottom: 0em !important;
}
// Colors
$green: var(--v-success-base);
$primary: var(--v-primary-base);
$white: #fff;

</style>