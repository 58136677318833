<template>
  <div class="form-add-element">

    <div v-if="isShown === false">
      <div class="collapsed">
        <v-btn
            small
            depressed
            rounded
            color="secondary"
            v-on:click="isShown = true"
        >
          <v-icon dense class="btn-icon">mdi-plus</v-icon>
          <span class="btn-text">Element toevoegen</span>
        </v-btn>
      </div>
    </div>
    <div v-else class="open">
      <h3 class="py-2">
        Element toevoegen
        <v-btn
            small
            depressed
            color="secondary"
            v-on:click="isShown = false"
        >
          <v-icon left>mdi-close</v-icon>
          Sluiten
        </v-btn>
      </h3>
      <v-tabs v-model="tab"
              centered
              class="white rounded-lg"
              icons-and-text>
        <v-tab href="#tab-1">
          Alle elementen
          <v-icon>mdi-pound</v-icon>
        </v-tab>

        <v-tab href="#tab-2"
               :disabled="disableFavorites">
          Favorieten
          <v-icon>mdi-heart</v-icon>
        </v-tab>
        <v-tabs-items class="transparent-body" v-model="tab">
          <v-tab-item
              key="1"
              value="tab-1">
            <v-card flat>
              <v-card-text>

                <span v-for="btn in formAddElementTypes" :key="btn.text">
                  <v-btn
                      v-if="!btn.premium"
                      v-on:click="addRow(btn)"
                      class="mr-2 mt-2"
                      color="secondary"
                      small
                      outlined
                  >
                    <v-icon left dense>mdi-{{ elementTypeIcons[btn.elementType] }}</v-icon> {{ btn.text }}
                  </v-btn>
                  <v-btn
                    v-if="btn.premium"
                    v-on:click="showPremiumModal(btn.elementType)"
                    class="mr-2 mt-2"
                    color="secondary"
                    small
                    outlined
                  >
                    <v-icon left dense>mdi-{{ elementTypeIcons[btn.elementType] }}</v-icon> {{ btn.text }} <v-badge offset-x="2" offset-y="2" icon="mdi-lock"></v-badge>
                  </v-btn>
                </span>

              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
              class="transparent-body"
              key="2"
              value="tab-2">
            <v-card flat class="transparent-body">
              <v-card-text class="transparent-body">
                <template v-if="favoriteElements.length >= 1">
                  <v-badge
                      color="error"
                      v-for="favorite in favoriteElements"
                      :key="favorite.uuid"
                      offset-x="20"
                      offset-y="20"
                      overlap
                      label="Verwijder favoriet"
                  >
                    <v-icon slot="badge"  @click="deleteFavorite(favorite)">
                      mdi-close
                    </v-icon>
                    <v-btn
                        slot="default"
                        v-on:click="addFavoriteRow(favorite)"
                        class="mr-2 mt-2"
                        color="secondary"
                        small
                        outlined
                    >
                      <v-icon left dense>mdi-{{ elementTypeIcons[favorite.elementType] }}</v-icon> {{ favorite.favoriteName }}
                    </v-btn>
                  </v-badge>
                </template>
                <template v-else>
                  U heeft nog geen favorieten toegevoegd.
                </template>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

    </div>

    <premium-wall :client_uuid="currentClientUUID" :module="premiumModule" :shown="premiumModalShown" @close="premiumModalShown = false"></premium-wall>

  </div>
</template>

<script>
import { uuid } from 'vue-uuid'; // uuid object is also exported to things outside Vue instance.
import userHelpers from "@/mixins/userHelpers";
import {cloneDeep} from 'lodash';
import eformHelpers from "@/mixins/eformHelpers";
import {mapActions, mapGetters} from "vuex";
import clientHelpers from "@/mixins/clientHelpers";
import premiumWall from "@/components/PremiumWall";

export default {
  name: 'EformElementAdd',
  components: {
    premiumWall,
  },
  mixins: [
    userHelpers,
    eformHelpers,
    clientHelpers
  ],
  props: {
    form_type: {
      type: String,
      default: 'default'
    },
    formSchema: Array,
    shown: Boolean,
    index: Number,
    disabledElementTypes: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  data() {
    return {
      tab: null,
      disableFavorites: false,
      premiumModule: '',
      premiumModalShown: false,
    }
  },
  async created() {
    await this.$store.dispatch('getFavoriteElements');
    // Load client
    await this.loadClient(this.$store.getters.CurrentClient)
  },
  computed: {
    ...mapGetters({User: "StateUser"}),
    favoriteElements() {
      return this.$store.state.favorites.elements;
    },
    isShown: {
      get() {
        return this.shown
      },
      set(value) {
        this.shown = value;
      }
    },
    currentClientUUID() {
      return this.$store.getters.CurrentClientUUID || "" // Fallback to empty string if no set
    },
    formAddElementTypes: function() {
      let options = [
        {
          elementType: 'text',
          text: 'Tekstveld',
          schema: {
            validation: [['required']],
          }
        },
        {
          elementType: 'textarea',
          text: 'Tekstvlak',
          schema: {
            type: 'textarea',
            validation: [['required']],
            rows: 3,
          }
        },
        {
          elementType: 'number',
          text: 'Nummer',
          schema: {
            type: 'decimal', // It may be preferable to use a text input rather than a number input for accessibility reasons. You can use the inputmode="numeric" and pattern="[0-9]*" attributes to force a number keypad for mobile users.
            inputmode: 'decimal',
            validation: [['number'],['required']],
            help: 'Vul een nummer in, voor decimale getallen kunt u een "." gebruiken.'
          }
        },
        {
          elementType: 'email',
          text: 'E-mail',
          schema: {
            type: 'email',
            validation: [['email'],['required']],
            help: 'Vul een e-mailadres in.'
          }
        },
        {
          elementType: 'date',
          text: 'Datum',
          schema: {
            type: 'date',
            validation: [['required']],
          }
        },
        {
          elementType: 'time',
          text: 'Tijd',
          schema: {
            type: 'time',
            validation: [['required']],
          }
        },
        {
          elementType: 'datetime',
          text: 'Datum en tijd',
          schema: {
            type: 'datetime-local',
            validation: [['required']],
          }
        },
        {
          elementType: 'checkbox_single',
          text: 'Checkbox',
          schema: {
            type: 'checkbox',
          }
        },
        {
          elementType: 'checkbox_multiple',
          text: 'Checkboxes',
          schema: {
            type: 'checkbox',
            options: [
              {value: 'eerste', label: 'Eerste', unsaved: true},
              {value: 'tweede', label: 'Tweede', unsaved: true},
              {value: 'derde', label: 'Derde', unsaved: true},
            ],
            viewMode: 'horizontal',
            'wrapper-class': ['eform-viewmode-horizontal'],
            validation: [['required']],
          }
        },
        {
          elementType: 'radio',
          text: 'Meerkeuzevraag',
          schema: {
            type: 'radio',
            options: [
              {value: 'eerste', label: 'Eerste', unsaved: true},
              {value: 'tweede', label: 'Tweede', unsaved: true},
            ],
            viewMode: 'horizontal',
            'wrapper-class': ['eform-viewmode-horizontal'],
            validation: [['required']],
          }
        },
        {
          elementType: 'select',
          text: 'Pulldown',
          schema: {
            type: 'select',
            options: [
              {value: null, label: '- Selecteer een optie -'},
              {value: 'eerste', label: 'Eerste', unsaved: true},
              {value: 'tweede', label: 'Tweede', unsaved: true},
              {value: 'derde', label: 'Derde', unsaved: true},
            ],
            placeholder: "- Selecteer een optie -",
            validation: [['required']],
          }
        },
        {
          elementType: 'file',
          text: 'Bestand',
          schema: {
            type: 'file',
            disabled: true,
            validation:[['maxFileSize'],['required']],
            help: 'Maximale bestandsgrootte: 20Mb.',
            fileDoRename: false,
          }
        },
        {
          elementType: 'image',
          text: 'Foto',
          schema: {
            type: 'image',
            validation: [['maxFileSize'],['required'],["mime","image/jpeg","image/png","image/gif"]],
            help: 'Toegestane bestandsextenties: jpg jpeg png gif. Maximale bestandsgrootte: 20Mb.',
            accept: 'image/*',
            fileDoRename: false,
            useOnlyCamera: true,
          }
        },
        {
          elementType: 'qrCodeScanner',
          text: 'QR code scanner',
          schema: {
            type: 'eformQrCodeScanner',
          }
        },
        {
          elementType: 'header',
          text: 'Koptekst',
          schema: {
            component: 'h2',
          }
        },
        {
          elementType: 'signature',
          text: 'Handtekening',
          schema: {
            type: 'signature',
            help: 'Teken uw handtekening binnen het witte vlak.',
            validation: [['required']],
            signatureSize: 'big',
            signatureLoadEnabled: false,
          },
        },
        {
          elementType: 'subform',
          text: 'Subformulier',
          schema: {
            type: 'subform',
            registrationLabel: 'Registratie',
            validation: [['required']],
            subform: {
              values: {},
              rules: [],
              schema: [],
            },
          },
        },
        {
          elementType: 'calculation',
          text: 'Berekening',
          schema: {
            type: 'eformCalculation',
            formula: '',
          }
        },
      ]

      let element_map = {
        premium: true, // Disabled by default. Will be enabled when the client module has been enabled.
        elementType: 'map',
        text: 'Kaart',
        schema: {
          type: 'eformMap',
          validation: [['required']],
          mapSettings: {
            featuresMaxSelect: 1,
            mapLayers: [],
            center: {
              lon: 5.387287657315901,
              lat: 52.15523010839274
            },
            zoomlevel: 7,
            featureStyles: [
              { status: 'open', color: '#3399CC' },
              { status: 'closed', color: '#e20000' },
            ],
            featureActions: []
          }
        }
      }
      // Check if user has permission to add components
      if (this.clientModuleMapElementEnabled) {
        element_map.premium = false
      }
      // Add element
      options.push(element_map);


      let element_gps = {
        premium: true, // Disabled by default. Will be enabled when the client module has been enabled.
        elementType: 'gps',
        text: 'Locatie',
        schema: {
          type: 'eformGps',
          validation: [['required']],
          'validation-messages': {
            required: 'Een accurate locatie is verplicht.',
          },
          mapSettings: {
            setManualLocation: true,
            tracking: false,
            minAccuracy: 25,
            mapLayers: [],
            center: {
              lon: 5.387287657315901,
              lat: 52.15523010839274
            },
            zoomlevel: 7,
          }
        },
      }
      // Check if user has permission to add components
      if (this.clientModuleLocationElementEnabled) {
        element_gps.premium = false
      }
      // Add element
      options.push(element_gps);


      let element_tracking = {
        premium: true, // Disabled by default. Will be enabled when the client module has been enabled.
        elementType: 'tracking',
        text: 'Track en trace',
        schema: {
          type: 'eformTracking',
          validation: [['required']],
          mapSettings: {
            setManualLocation: false,
            tracking: true,
            minAccuracy: 25,
            mapLayers: [],
            center: {
              lon: 5.387287657315901,
              lat: 52.15523010839274
            },
            zoomlevel: 7,
          }
        },
      }
      // Check if user has permission to add components
      if (this.clientModuleTrackingElementEnabled) {
        element_tracking.premium = false
      }
      // Add element
      options.push(element_tracking);


      options.push(
          {
            elementType: 'search',
            text: 'Zoeken',
            schema: {
              type: 'eformSearch',
              validation: [['required']],
              searchSettings: {
                datasourceId: null,
                maxValues: 1,
                itemStyles: [],
                itemAction: 'doNothing',
              }
            }
          }
      );

      // Check if user is 'admin'
      if (this.userHasRole('admin')) {
        options.push(
            {
              elementType: 'linkedForm',
              text: 'Gekoppeld formulier',
              schema: {
                type: 'linkedForm',
                formId: '',
                validation: [],
              },
            },
        );
        // Add the eformComputed element
        options.push(
            {
              elementType: 'computed',
              text: 'Berekend',
              schema: {
                type: 'eformComputed',
                elementToWatch: '',
                parserType: '',
              },
            },
        );
      }

      options.push({
        elementType: 'staticText',
        text: 'Tekstvlak (alleen bekijken)',
        schema: {
          staticText: '<p>Vaste tekst</p>',
        }
      })
      options.push({
        elementType: 'imageTag',
        text: 'Afbeelding (alleen bekijken)',
        schema: {
          component: 'img',
          image: [],
          width: '300',
          height: '',
          children: '',
        }
      })
      options.push({
        elementType: 'staticFile',
        text: 'Bestand (alleen bekijken)',
        schema: {
          type: 'eformStaticFile',
          file: [],
        }
      })

      if (this.form_type === 'workflow') {
        options.push(        {
          elementType: 'stateTransition',
          text: 'Fase overgang',
          schema: {
            type: 'eformStateTransition',
            next_phase: 2,
            assign_to_user: 0,
          }
        })
      }

      // Remove disabled elementTypes
      options = this.removeDisabledElementTypes(options)

      return options;
    },
  },
  methods: {
    ...mapActions(["getFavoriteElements", "removeFavoriteElement"]),
    removeDisabledElementTypes(options) {
      // Loop over all element options
      for(let i = options.length -1; i >= 0 ; i--){
        if (this.disabledElementTypes.includes(options[i].elementType)) {
          // Remove option if its in the disabled element types.
          options.splice(i, 1);
        }
      }
      return options
    },
    addRow(btn) {
      // Clone the element to prevent the original from being edited
      let elementDefault = cloneDeep(btn)

      let highestElementCount = this.getHighestElementCount(elementDefault.elementType)
      // Add 1 to the current highest count
      let elementCount = ++highestElementCount
      // Create a new element that we are going to insert into the form schema.
      let element = {
        name: elementDefault.elementType+elementCount,
        label: elementDefault.text,
        elementType: elementDefault.elementType,
        uuid: uuid.v4(),
        unsaved: true,
        ...elementDefault.schema
      }
      // Close add element button bar
      this.isShown = false
      // Add element to formSchema
      this.$emit('formAddElement', element, this.index)
    },
    addFavoriteRow(favorite) {
      // Clone the element to prevent the original from being edited
      let newElement = cloneDeep(favorite)

      let highestElementCount = this.getHighestElementCount(newElement.elementType)
      // Add 1 to the current highest count
      let elementCount = ++highestElementCount
      // Create a new element that we are going to insert into the form schema.
      newElement.name = newElement.elementType+elementCount;
      newElement.uuid = uuid.v4();
      newElement.unsaved = true;

      // Close add element button bar
      this.isShown = false
      // Add element to formSchema
      this.$emit('formAddElement', newElement, this.index)
    },
    deleteFavorite(favorite) {
      if (
          confirm('Weet je zeker dat je favoriet "'+favorite.favoriteName+'" wilt verwijderen?')
      ) {
        this.$store.dispatch('removeFavoriteElement', {favoriteElementId: favorite.uuid});
      }
    },
    getHighestElementCount(type) {
      let elementHighestCount = 0
      // Loop over form schema
      for (let i = 0; i < this.formSchema.length; i++) {
        let element = this.formSchema[i]
        // Fallback to 'text' when type is not set.
        let elementType = element.elementType || 'text'
        // Only check elementTypes that are the same as the one we are adding
        if (elementType !== type) {
          continue
        }
        // Get the suffix number of this element in the schema
        let elementSuffixNumber = this.getElementSuffixNumber(element)
        if (elementSuffixNumber > elementHighestCount) {
          // Set new highest element count
          elementHighestCount = elementSuffixNumber
        }
      }
      // Return the current highest count
      return parseInt(elementHighestCount)
    },
    getElementSuffixNumber(element) {
      let count = 0;
      // Fallback to 'text' when type is not set.
      let elementType = element.elementType || 'text'
      // Get highest count
      if (element.name) {
        let count = element.name.replace(elementType, '');
        // text = ''
        // text1 = '1'
        // text2 = '2'
        if (count !== '') {
          return parseInt(count)
        }
      }
      return count
    },
    showPremiumModal(module) {
      switch (module) {
        case 'map':
          this.premiumModule = 'map_element'
          this.premiumModalShown = true
          break;
        case 'gps':
          this.premiumModule = 'location'
          this.premiumModalShown = true
          break;
        case 'tracking':
          this.premiumModule = 'tracking'
          this.premiumModalShown = true
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-add-element {
  position: relative;
  text-align: center;
  margin-top: -8px;
  margin-bottom: -8px;
  z-index: 4;

  &::before {
    border-bottom: 1px solid #eee;
    content: "";
    position: absolute;
    top: 13px;
    left: 0;
    height: 1px;
    width: 100%;
    z-index: 0;
  }

  .collapsed {
    position: relative;
    z-index: 1;

    .btn-text {
      width: 0;
      opacity: 0;
      transition: all 250ms ease-in-out;
      transition-delay: 100ms;
    }
    &:hover {
      .btn-text {
        opacity: 1;
        width: 150px;
      }
    }
  }

  .open {
    position: relative;
    padding: 20px 0 30px;
  }

}
.transparent-body {
  background: transparent !important;
}
</style>
