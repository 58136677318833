import axios from "axios";

export default {
    methods: {
        async getFolderOptions(client_id) {
            let options = {};
            let response = await axios.get('/api/client/' + client_id + '/folders');
            if (response && response.status && response.status === 200) {
                response.data.forEach(function(item) {
                    options[item.folder_id] = item.folder_name;
                });
            }
            return options;
        },
    }
};