<template>
  <div>

    <div class="d-flex justify-space-between mb-3">

      <template v-if="trashed !== 1">
        <!-- Show download button with multiple options -->
        <v-menu v-if="userHasPermission('eform manage form registrations download with attachments')" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
                :loading="(csvDownloadLoading || csvDownloading)"
                :disabled="(csvDownloadLoading || csvDownloading) || items.length === 0"
                class="mb-4"
                color="primary"
                depressed
                v-on="on"
            >
              Download registraties
              <v-icon
                  right
                  dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="downloadCSV('csv-with-attachments')">
              <v-list-item-title>Download met bijlagen</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadCSV('csv')">
              <v-list-item-title>Download zonder bijlagen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Show single download button without options -->
        <v-btn
            v-else-if="userHasPermission('eform manage form registrations download')"
            :loading="(csvDownloadLoading || csvDownloading)"
            :disabled="(csvDownloadLoading || csvDownloading) || items.length === 0"
            class="mb-4"
            color="primary"
            depressed
            @click="downloadCSV('csv')"
        >
          Download registraties
          <v-icon
              right
              dark
          >
            mdi-cloud-download
          </v-icon>
        </v-btn>
      </template>

      <div v-if="trashed === 1">
        Registraties in de prullenbak worden na 30 dagen definitief verwijderd.
      </div>

      <v-btn-toggle
          class="ml-auto"
          v-model="tab"
          color="primary"
          mandatory
          dense
          :background-color="$vuetify.theme.themes[theme].background"
      >
        <v-btn value="completed">
          Voltooide registraties
        </v-btn>
        <v-btn value="draft" v-if="myForm.form_type ==='workflow'">
          Onvoltooide registraties
        </v-btn>
        <v-btn value="trash">
          Prullenbak
        </v-btn>
      </v-btn-toggle>

    </div>

      <!-- Load webfonts before creating the canvas -->
      <v-overlay
          class="text-center align-content-center text--primary"
          :value="csvDownloading"
          z-index="20"
      >
        <v-card
                width="50vw"
                align="center"
                light
                justify="center">
          <v-card-title>
            Download voortgang:
          </v-card-title>
          <v-card-subtitle class="text-left" v-if="csvDownload.processed_items === 0">
            Het kan tot een minuut duren voordat de download wordt gestart er items worden verwerkt
          </v-card-subtitle>
          <v-card-text>
            <v-progress-linear
                striped
                :value="progressPercentage"
                height="25">
              <strong>{{ progressPercentage }}% ({{csvDownload.processed_items }}/{{csvDownload.total_items}})</strong>
            </v-progress-linear>
            <v-progress-linear
                indeterminate
                v-if="csvDownloading">
            </v-progress-linear>
          </v-card-text>

          <v-card-actions class="pt-12">
            <v-btn color="error" @click="stopDownloading()">
              Annuleer download
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-overlay>

    <div class="registrations-list-table">
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="itemsLoading"
          :items-per-page="10"
          :sort-by="sortBy"
          :sort-desc="[true]"
          class="rounded-lg"
          @click:row="rowClick"
          :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
          fixed-header height="60vh"
      >
        <template #header="{ props }">
          <tr>
            <th
                v-for="header in props.headers"
                :key="header.value+'-filter'"
            >
              <div v-if="filters.hasOwnProperty(header.value)" class="mt-2 mr-2">
                <div v-if="filters[header.value].type === 'date'">
                  <eform-table-filter-date
                      :filter-key="header.value"
                      :value="filters[header.value].value"
                      @value-change="updateFilter"
                  ></eform-table-filter-date>
                </div>
                <div v-else-if="filters[header.value].type === 'integer'">
                  <eform-table-filter-integer
                      :filter-key="header.value"
                      :value="filters[header.value].value"
                      @value-change="updateFilter"
                  ></eform-table-filter-integer>
                </div>
              </div>
            </th>
          </tr>
        </template>
        <template #item.registration_date="{ item }">
          {{ item.registration_date|formatDate }}
        </template>
        <template #item.deleted_at="{ item }">
          {{ item.deleted_at|formatDate }}
        </template>
        <template #item.registration_status="{ item }">
          {{ item.registration_status }}
        </template>
        <template #item.webhooks="{ item }">

        <span
            v-for="(value, index) in item.webhooks"
            :key="index">

          <v-tooltip bottom v-if="value.hook_status === 'pending'">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-clock-outline</v-icon>
            </template>
            <span>In wachtrij: {{ value.hook_name }}</span>
          </v-tooltip>

          <v-tooltip bottom v-else-if="value.hook_status === 'error'">
            <template v-slot:activator="{ on }">
              <v-icon color="error" v-on="on">mdi-alert</v-icon>
            </template>
            <span>Fout: {{ value.hook_name }}</span>
          </v-tooltip>

          <v-tooltip bottom v-else-if="value.hook_status === 'success'">
            <template v-slot:activator="{ on }">
              <v-icon color="success" v-on="on">mdi-check-circle</v-icon>
            </template>
            <span>Geslaagd: {{ value.hook_name }}</span>
          </v-tooltip>

        </span>

        </template>
        <template #item.registration_view="{ item }">
          <v-btn text color="primary" :to="{ name: 'form_registrations_view', params: {form_id: form_id, registration_id: item.registration_id} }">
            Bekijk
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <PeriodicRegistrationMails v-if="userHasPermission('eform manage form registrations weekly mail')" :form_id="form_id" class="mt-4"/>
  </div>
</template>

<script>
import axios from "axios";
import userHelpers from "../../mixins/userHelpers";
import PeriodicRegistrationMails from "../../components/PeriodicRegistrationMails";
import EformTableFilterDate from "@/components/table-filters/EformTableFilterDate";
import EformTableFilterInteger from "@/components/table-filters/EformTableFilterInteger";
import _, {debounce} from "lodash";

export default {
  name: "FormRegistrations",
  components: {
    PeriodicRegistrationMails,
    EformTableFilterDate,
    EformTableFilterInteger,
  },
  mixins: [
    userHelpers,
  ],
  data () {
    return {
      itemsLoading: false,
      tab: 'completed',
      csvDownloadLoading: false,
      csvDownloading: false,
      csvDownload: {
        queue_id: 0,
        total_items: 0,
        processed_items: 0,
        download_url: '',
        download_complete: false,
      },
      downloadProgressPolling: null,
      form_id: this.$route.params.form_id,
      total: 0,
      items: [],
      filters: {
        registration_serial_number: {
          type: 'integer',
          value: {
            value: '',
            min: '',
            max: '',
          },
        },
        registration_date: {
          type: 'date',
          value: {
            value: '',
            min: '',
            max: '',
          },
        },
      },
    }
  },
  props: {
    myForm: Object,
  },
  mounted () {
    // Get items
    this.getDataFromApi();
  },
  computed: {
    trashed() {
      return this.tab === 'trash' ? 1 : 0
    },
    registrationStatus() {
      return this.tab === 'completed' ? 'completed' : 'draft'
    },
    headers() {

      let headers = [{
        text: 'Bekijk',
        value: 'registration_view',
      }]

      // Add 'deleted_at' header when trashed filter is enabled
      if (this.trashed === 1) {
        headers.push({
          text: 'In prullenbak geplaatst op',
          value: 'deleted_at',
        })
      }

      // Add default headers
      headers.push(...[
        {
          text: 'Registratiedatum',
          value: 'registration_date',
        },
        {
          text: 'Serienummer',
          value: 'registration_serial_number',
        },
        {
          text: 'Integraties',
          value: 'webhooks',
        },
        {
          text: 'Gebruiker',
          value: 'user',
        },
      ])
      // Add registration phase
      if (this.tab === 'draft') {
        headers.push(...[
          {
            text: 'Fase',
            value: 'registration_phase',
          },
        ]);
      }

      // Set headers from the form schema
      headers.push(...this.getHeaders());

      return headers;
    },
    sortBy() {
      if (this.trashed === 1) {
        return ['deleted_at']
      }
      return ['registration_date']
    },
    progressPercentage() {
      if (this.csvDownload.total_items > 0) {
        let progressPercentage = (this.csvDownload.processed_items / this.csvDownload.total_items) * 100;
        return (Math.ceil(progressPercentage))
      }
      else {
        return 0;
      }
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  watch: {
    tab() {
      // Reload data with the new filter
      this.getDataFromApi()
    }
  },
  methods: {
    pollDownloadProgress() {
      this.downloadProgressPolling = setInterval(() => {
        this.getDownloadProgress();
      }, 5000)
    },
    getDataFromApi () {
      this.itemsLoading = true;
      let params = this.getFilterParams();
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/registrations', {params: params})
          .then(response => {
            // Get registration values. Create an item array for the table
            let registrationsValues = this.getRegistrationValueArray(response.data)

            this.items = registrationsValues
            this.total = response.data.length
            this.itemsLoading = false;
          })
          .catch(error => {
            console.log(error)
          })
    },
    /**
     * Builds a list of filter ULR parameters.
     *
     * The structure is the same as the Drupal JSON:API filtering.
     * Except we have shortened 'filter' to 'f' and 'condition' to 'c' to prevent URLs that are longer than 2000 characters.
     *
     * @see https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/filtering
     *
     * @returns {{}}
     */
    getFilterParams() {
      let params = {}
      let index = 0;

      // Add trashed filter
      params['f[trashed][c][path]'] = 'deleted_at';
      params['f[trashed][c][operator]'] = this.trashed ? 'IS NOT NULL' : 'IS NULL';

      // Add registration status filter
      if (!this.trashed) {
        params['f[status][c][path]'] = 'registration_status';
        params['f[status][c][operator]'] = '=';
        params['f[status][c][value]'] = this.registrationStatus;
      }

      // Add table filters
      for (const filterKey in this.filters) {
        // Get the filter
        let filter = this.filters[filterKey]
        // Add integer filters
        if (filter.type === 'integer') {
          if (!_.isEmpty(filter.value.min)) {
            index++
            params[`f[${index}][c][path]`] = filterKey;
            params[`f[${index}][c][operator]`] = '>=';
            params[`f[${index}][c][integer]`] = filter.value.min;
          }
          if (!_.isEmpty(filter.value.max)) {
            index++
            params[`f[${index}][c][path]`] = filterKey;
            params[`f[${index}][c][operator]`] = '<=';
            params[`f[${index}][c][integer]`] = filter.value.max;
          }
        }
        // Add date filters
        else if (filter.type === 'date') {
          if (!_.isEmpty(filter.value.min)) {
            index++
            params[`f[${index}][c][path]`] = filterKey;
            params[`f[${index}][c][operator]`] = '>=';
            params[`f[${index}][c][date]`] = filter.value.min;
          }
          if (!_.isEmpty(filter.value.max)) {
            index++
            params[`f[${index}][c][path]`] = filterKey;
            params[`f[${index}][c][operator]`] = '<=';
            params[`f[${index}][c][date]`] = filter.value.max;
          }
        }
      }
      return params;
    },
    getRegistrationValueArray(items) {
      let itemsArray = []
      // Get the 'values' from the registration JSON and make it into a simple array
      const keys = Object.keys(items)
      for (const key of keys) {
        let values = {}
        // Add form values if they're set
        if (typeof items[key].values != 'undefined') {
          values = items[key].values
        }
        values = this.formatValues(values, items[key].schema);

        // Add view link
        values.registration_id = items[key]._id
        // Add serial number
        values.registration_serial_number = items[key].registration_serial_number
        // Add registration date
        values.registration_date = items[key].registration_date;
        // Add registration phase
        values.registration_phase = items[key].registration_phase;
        // Add deleted date
        if (items[key].deleted_at) {
          values.deleted_at = items[key].deleted_at;
        }
        // Add username
        values.user = items[key].registration_user_id.username
        // Add webhook integrations
        values.webhooks = []
        if (typeof items[key].webhook_integrations != 'undefined') {
          // Loop over all webhooks
          for (let i = 0; i < items[key].webhook_integrations.length; i++) {
            values.webhooks.push({
              hook_name: items[key].webhook_integrations[i].hook_name,
              hook_status: items[key].webhook_integrations[i].hook_status
            })
          }
        }
        // Add form values
        itemsArray.push(values)
      }
      return itemsArray;
    },
    formatValues(values, schema) {
      let newValues = {};
      for(const [key, value] of Object.entries(values)) {
        let index = schema.findIndex(x => x.name === key);
        if (index !== -1) {
          let returnValue = this.formatValue(value, schema[index]);
          newValues[key] = returnValue;
          values[key] = returnValue;
        }
      }

      return newValues;
    },
    formatValue(value, element) {
      const elementType = element.elementType;

      switch (elementType) {
        case 'checkbox_single':
          if (value === true) {
            return 'Ja';
          }
          else {
            return 'Nee';
          }
        case 'checkbox_multiple':
        case 'select':
        case 'radio':
          return this.formatOptionsAsLabels(element, value);
        case 'file':
        case 'image':
        case 'subform':
          if (Array.isArray(value)) {
            return value.length;
          }
          break;
        case 'map':
          return this.formatMapValues(value);
        case 'signature':
          if (value.length > 0) {
            return 'Ingevuld';
          }
          break
        case 'search':
          return this.formatSearchValues(value);
        case 'gps':
          if (value.lat !== 0 && value.lon !== 0) {
            return 'Ja';
          }
          else {
            return 'Nee';
          }
          case 'tracking':
            if (value && value.length > 1) {
              return 'Ja';
            }
            else {
              return 'Nee';
            }
        case 'stateTransition':
          return value.next_phase;
      }

      return value;
    },
    formatOptionsAsLabels(element, value) {
      // Get all labels of the selected option(s)
      if (Array.isArray(value)) {
        let selectedOptions = [];
        for (let i = 0; i < value.length; i++) {
          let option = element.options.find(x => x.value === value[i]);
          if (option) {
            selectedOptions.push(option.label);
          }
        }
        return selectedOptions.join(", ");
      }
      else {
        let option = element.options.find(x => x.value === value);
        if (option) {
          return option.label;
        }
      }
    },
    formatMapValues(value) {
      var mapValues = [];
      if (value && value.features) {
        var features = value.features;
        // var featureString = '<ul>';

        for(let i = 0; i < features.length; i++) {
          if (features[i].properties && features[i].properties.name) {
            mapValues.push(features[i].properties.name);
          }
        }

        // featureString += '</ul>';
        return mapValues.join(", ");
      }
    },
    formatSearchValues(values) {
      let searchValues = [];
      if (values.length) {
        // var itemString = '<ul>';
        for(let i = 0; i < values.length; i++) {
          if (values[i].name) {
            searchValues.push(values[i].name);
          }
        }
        // itemString += '</ul>';
        return searchValues.join(", ");
      }
    },
    formatGpsValues(values) {
      let searchValues = [];
      if (values.length) {
        // var itemString = '<ul>';
        for(let i = 0; i < values.length; i++) {
          if (values[i].name) {
            searchValues.push(values[i].name);
          }
        }
        // itemString += '</ul>';
        return searchValues.join(", ");
      }
    },
    getHeaders() {
      let headers = []
      if (this.myForm) {
        // Get form schema
        let schema = this.myForm.schema;
        // Loop over the schema
        const keys = Object.keys(schema)
        for (const key of keys) {
          let element = schema[key]
          // Skip HTML components
          if (element.component || element.elementType === 'linkedForm') {
            continue
          }
          // Create header
          let dataname = element.name
          let label = element.label

          if (label.length >= 150) {
            label = label.substring(0, 150) + '...';
          }
          // Add to headers
          headers.push({text: label, value: dataname})
        }
      }

      // Return the headers
      return headers
    },
    downloadCSV(format) {
      let params = this.getFilterParams()
      this.csvDownloadLoading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/registrations/queue/download/'+format, {params: params})
          .then(response => {
            if (response.data) {
              this.csvDownload.download_url = response.data.download_url;

              // Queue set, we need to wait for the download
              if (response.data.queue_id && response.data.queue_id !== false) {
                this.csvDownload.queue_id = response.data.queue_id;
                this.csvDownload.total_items = response.data.total;
                this.csvDownload.processed_items = response.data.processed;

                this.$toast.success("Uw download wordt voorbereid en begint over 1-2 minuten. U ziet hier direct de voortgang en als deze klaar is wordt hij automatisch gedownload.", { timeout: 10000 });
                this.csvDownloading = true;
                this.pollDownloadProgress();
              }
              else {
                window.location = response.data.download_url;
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.csvDownloadLoading = false)
    },
    getDownloadProgress() {
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/registrations/queue/progress/'+this.csvDownload.queue_id)
          .then(response => {
            if (response.data) {
              this.csvDownload.total_items = response.data.total;
              this.csvDownload.processed_items = response.data.processed;

              if (this.progressPercentage >= 100) {
                this.csvDownloading = false;
                let downloadUrl = this.csvDownload.download_url;
                this.csvDownload = {
                  queue_id: 0,
                  total_items: 0,
                  processed_items: 0,
                  download_url: '',
                  download_complete: false,
                }

                clearInterval(this.downloadProgressPolling);

                window.location = downloadUrl;
              }
            }
          })
          .catch(error => {
            clearInterval(this.downloadProgressPolling);
            console.log(error)
          })
          .finally(() => this.csvDownloadLoading = false)
    },
    stopDownloading() {
      this.csvDownloading = false;
      clearInterval(this.downloadProgressPolling);
      this.$toast.clear();
    },
    rowClick(item) {
      this.$router.push({ name: 'form_registrations_view', params: {form_id: this.$route.params.form_id, registration_id: item.registration_id} })
    },
    updateFilter: debounce(function (filterKey, value) {
      // Set new filters values
      this.filters[filterKey].value = value
      // Reload data with the new filter
      this.getDataFromApi()
    }, 500),
  },
  beforeDestroy: function() {
    clearInterval(this.downloadProgressPolling);
  }
}
</script>

<style lang="scss">
.v-data-table-header th {
  vertical-align: bottom;
}
.registrations-list-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
// Make table filters textfield a lot smaller
.registrations-list-table .eform-registration-text-field {
  .v-input__slot {
    min-height: 34px !important;
  }
  .v-label {
    top: 13px !important;
  }
  input {
    font-size: 13px;
    margin-top: 1px !important;
  }
}
</style>
