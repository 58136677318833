<template>
  <div>
    <v-btn @click="downloadDataAsText" class="mb-2" block>1. Download opgeslagen registratie data</v-btn>

    <v-btn class="mb-2"
           block
           v-clipboard:copy="getDataAsText()"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
    >2. Kopieer naar klembord</v-btn>

    <v-btn block class="mb-2" @click="selectAllText">3. Selecteer alle tekst (u moet zelf de tekst kopieren en plakken in naar een bestand</v-btn>

    <pre id="unsavedRegistrations" class="code">
      {{ unsaved_registrations }}
    </pre>
  </div>
</template>

<script>
export default {
  data: () => ({
    unsaved_registrations: null,
  }),
  mounted() {
    // Get the unsaved registrations from the store
    this.unsaved_registrations = this.$store.state.unsyncedRegistrations;
  },
  methods: {
    /**
     * Download the unsaved registrations as a text file, raw without parsing it.
     */
    downloadDataAsText() {
      const blob = new Blob([JSON.stringify(this.unsaved_registrations, null, 2)], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'unsaved_registrations.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    getDataAsText() {
      return JSON.stringify(this.unsaved_registrations, null, 2);
    },
    onCopySuccess() {
      let $vm = this;
      $vm.$toast.success("Gekopieerd naar klembord");
    },
    onCopyError() {
      let $vm = this;
      $vm.$toast.error("Kon niet kopieren.");
    },
    selectAllText() {
      const preElement = document.getElementById('unsavedRegistrations');
      const range = document.createRange();
      range.selectNodeContents(preElement);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    },
  }
}
</script>

<style scoped>
.code {
  background: #fff;
  overflow: auto;
  max-height: 600px;
  margin: 40px;
}
</style>