<template>
  <div>
    <FormulateInput
        :element="element"
        label="Voorbeeldtekst"
        type="text"
        v-model="element.placeholder" />

    <FormulateInput
        type="number"
        v-model="min" label="Minimum lengte"
        v-on:blur-context="updateMinCharacterValidation" />
    <FormulateInput
        type="number"
        v-model="max" label="Maximum lengte"
        v-on:blur-context="updateMaxCharacterValidation"/>
    <FormulateInput
        type="number"
        v-model="element.rows"
        label="Rijen"/>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: 'EformElementEditTextarea',
  props: {
    element: Object,
  },
  data: () => ({
    min: '',
    max: '',
  }),
  created() {
    this.setDefaultValues();
  },
  methods: {
    updateMinCharacterValidation() {
      if (this.min === '') {
        // Remove validation rule
        this.$emit('removeValidationRule', 'min');
      }
      else {
        // Add or update validation rule
        this.$emit('addOrUpdateValidationRule', {ruleName: 'min', value: this.min, applyTo: 'length'});
      }
    },
    updateMaxCharacterValidation() {
      if (this.max === '') {
        // Remove validation rule
        this.$emit('removeValidationRule', 'max');
      }
      else {
        // Add or update validation rule
        this.$emit('addOrUpdateValidationRule', {ruleName: 'max', value: this.max, applyTo: 'length'});
      }
    },
    setDefaultValues() {
      let ruleMin = this.getValidationRule('min');
      let ruleMax = this.getValidationRule('max');
      if (ruleMin !== false) {
        this.min = ruleMin[1];
      }
      if (ruleMax !== false) {
        this.max = ruleMax[1];
      }
      // Set default values for rows if it is not set
      if (!_.has(this.element, ['rows'])) {
        // Use $set to set to make sure the reactivity system is aware of the change
        this.$set(this.element, 'rows', 3);
      }
    },
    getValidationRule(ruleName) {
      // Returns the validation index when it exists. Returns FALSE when the ruleName does not exist.
      let length = this.element.validation.length;
      for (let i = 0; i < length; i++) {
        if (this.element.validation[i][0] === ruleName) {
          return this.element.validation[i];
        }
      }
      return false;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
