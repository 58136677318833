<template>
  <div>

    <v-row>
      <v-col>
        <FormulateInput
            :options="schemaElements"
            type="select"
            label="Element"
            help="Welke waarde uit welk element moet er gebruikt worden gebruikt?"
            validation="required"
            v-model="element.elementToWatch"
        />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col>
        <FormulateInput
            :options="{
                parseSchrederFixtureQrCodeLampAmount: 'Schreder QR Code armatuur - Aantal lampen',
                parseSchrederFixtureQrCodeColorTemp: 'Schreder QR Code armatuur - Kleurtemperatuur',
            }"
            type="select"
            label="Verwerker"
            help="Wat voor verwerker/functie moet er op de waarde toegepast worden?"
            validation="required"
            v-model="element.parserType"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    schema: Array,
  },
  computed: {
    schemaElements() {
      const elements = {};
      this.schema.forEach((element) => {
        elements[element.name] = element.label;
      });
      return elements;
    },
  },
}
</script>

<style lang="scss">

</style>
