import _, {get, has, isEmpty} from "lodash";
import eformElementFormatter from "@/mixins/eformElementFormatter";
import eformSanitzeHtmlHelpers from "@/mixins/eformSanitzeHtmlHelpers";

export default {
    mixins: [
        eformElementFormatter,
        eformSanitzeHtmlHelpers,
    ],
    data () {
        return {
            elementTypeIcons: {
                text: 'form-textbox',
                textarea: 'card-text-outline',
                number: 'numeric',
                email: 'email-outline',
                date: 'calendar-month',
                time: 'clock-outline',
                datetime: 'calendar-clock',
                checkbox_single: 'checkbox-marked-outline',
                checkbox_multiple: 'checkbox-multiple-marked-outline',
                radio: 'checkbox-marked-circle-outline',
                select: 'arrow-down-drop-circle-outline',
                file: 'paperclip',
                image: 'camera-outline',
                header: 'format-header-1',
                staticText: 'text',
                imageTag: 'image-outline',
                signature: 'draw',
                map: 'map-marker',
                subform: 'folder-open-outline',
                subform_metadata: 'folder-open-outline',
                subform_index: 'numeric-1-circle-outline',
                registration: 'form-select',
                search: 'magnify',
                gps: 'crosshairs-gps',
                tracking: 'map-marker-distance',
                linkedForm: 'file-link',
                calculation: 'calculator',
                qrCodeScanner: 'qrcode-scan',
                staticFile: 'download',
                autoIncrement: 'format-section',
                stateTransition: 'redo',
                computed: 'creation',
            },
            searchableFieldTypes: [
                'text',
                'textarea',
                'number',
                'decimal',
                'email',
                'date',
                'time',
                'datetime',
                'checkbox_single',
                'checkbox_multiple',
                'radio',
                'select',
                'radio',
                'subform',
                'map',
                'search',
            ],
            defaultRegistrationName: '$registratie.serienummer$ - $registratie.registratiedatum$',
        }
    },
    methods: {
        getDatanames(form, elementTypes = []) {
            let datanames = []
            // Loop over all elements in the form schema
            for (let i in form.schema) {
                if (elementTypes.length) {
                    if (elementTypes.includes(form.schema[i].elementType)) {
                        // Add element to the list
                        datanames.push({
                            dataname: form.schema[i].name,
                            label: form.schema[i].label,
                            elementType: form.schema[i].elementType
                        })
                    }
                }
                else {
                    // Add all elements
                    datanames.push({
                        dataname: form.schema[i].name,
                        label: form.schema[i].label,
                        elementType: form.schema[i].elementType
                    })
                }
            }
            return datanames
        },
        getDatanamesAsSelectList(form, elementTypes = []) {
            // Get all datanames in the form
            let datanames = this.getDatanames(form, elementTypes)
            // Build select list of datanames
            let datanamesSelectList = []
            for (let item of datanames) {
                // Add dataname items to selectlist
                datanamesSelectList.push({
                    value: item.dataname,
                    label: item.label
                })
            }
            return datanamesSelectList
        },
        // Returns all eform tokens, including registration tokens and all subform tokens.
        getEformTokens(eform_schema) {
            return this.getEformTokensFilterExclude(eform_schema);
        },
        getEformTokensFilterExclude(eform_schema, excludedTypes = []) {
            let tokens = [
                {
                    token: 'registratie.registratiedatum',
                    label: 'Registratiedatum',
                    elementType: 'registration'
                },
                {
                    token: 'registratie.invuller',
                    label: 'Registratie invuller',
                    elementType: 'registration'
                },
                {
                    token: 'registratie.formuliernaam',
                    label: 'Formuliernaam',
                    elementType: 'registration'
                },
                {
                    token: 'registratie.serienummer',
                    label: 'Serienummer',
                    elementType: 'registration'
                },
                {
                    token: 'nummering.hoofdstuk',
                    label: 'Nummering hoofdstuk',
                    elementType: 'autoIncrement',
                    description: 'Deze token werkt alleen in het Word template.',
                },
                {
                    token: 'nummering.afbeelding',
                    label: 'Nummering afbeelding',
                    elementType: 'autoIncrement',
                    description: 'Deze token werkt alleen in het Word template.',
                },
                {
                    token: 'nummering.tabel',
                    label: 'Nummering tabel',
                    elementType: 'autoIncrement',
                    description: 'Deze token werkt alleen in het Word template.',
                },
            ]
            // Add schema tokens
            tokens.push(...this.getTokensFromSchemaFilterExclude(eform_schema, excludedTypes))
            // Return all eform tokens
            return tokens
        },
        // Returns tokens from a schema or subform schema.
        getTokensFromSchema(schema) {
            return this.getTokensFromSchemaFilterExclude(schema);
        },
        getTokensFromSchemaFilterInclude(schema, includeElementTypes) {
            let tokens = this.getTokensFromSchema(schema);
            if (!includeElementTypes) {
                return tokens;
            }

            return tokens.filter(function (token) {
                return includeElementTypes.includes(token.elementType);
            });
        },
        getTokensFromSchemaFilterExclude(schema, excludedElementTypes = []) {
            let tokens = []

            // Add in an extra 'subform_index'. This is hidden in EformToken.vue on items that are not subforms.
            tokens.push({
                token: 'subform_index',
                label: 'Subformulier nummer',
                elementName: 'subform_index',
                elementType: 'subform_index',
            })

            // Loop over all elements in the form schema
            for (let element of schema) {
                if (excludedElementTypes.includes(element.elementType)) {
                    continue;
                }

                // Subform tokens
                if (element.elementType === 'subform') {
                    let subform_tokens_metadata = this.getTokensFromSchema(element.subform.schema)
                    let block_name = '$BLOCK_'+element.name.toUpperCase()+'$'

                    // Loop over all metadata tokens
                    // Create 'sum' tokens
                    for (let subform_token of subform_tokens_metadata) {
                        subform_token.token = element.name+'_metadata.'+subform_token.token+'.sum'
                        subform_token.subformName = element.name;
                        subform_token.metadataName = 'sum';
                    }

                    // Add subform metadata tokens. Add element without showing token (used for grouping)
                    if (!excludedElementTypes.includes("subform_metadata")) {
                        tokens.push({
                            label: element.label+' (metadata)',
                            elementName: element.name,
                            elementType: "subform_metadata",
                            subformTokenType: 'metadata',
                            children: subform_tokens_metadata,
                            isShown: false,
                            description: "Bevat formules van berekening velden. Deze tokens kunnen alleen gebruikt worden in een berekening veld.",
                        })
                    }

                    // Add block tokens. Add element without showing token (used for grouping)
                    tokens.push({
                        label: element.label+' (block)',
                        elementName: element.name,
                        elementType: element.elementType,
                        subformTokenType: 'block',
                        schema: element.subform.schema,
                        isShown: false,
                        description: 'Deze tokens werken in het Mail (handmatig) en Word template en kunnen alleen binnen een '+block_name+' gebruikt worden. Voor elke registratie in het subformulier wordt het '+block_name+' gekopieerd en ingevuld met de tokens.',
                    })

                    // Add Word table row tokens. Add element without showing token (used for grouping)
                    tokens.push({
                        label: element.label+' (Word tabelrij)',
                        elementName: element.name,
                        elementType: element.elementType,
                        subformTokenType: 'tablerow',
                        schema: element.subform.schema,
                        isShown: false,
                        description: 'Deze tokens werken alleen in het Word template en kunnen alleen binnen een tabel gebruikt worden. Voor elke registratie in het subformulier wordt er een extra tabel rij toegevoegd.',
                    })

                }

                // Add images tokens
                if (element.elementType === 'image') {
                    let image_tokens = []
                    image_tokens.push({
                        token: element.name+'#halve_pagina',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    image_tokens.push({
                        token: element.name+'#derde_pagina',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    image_tokens.push({
                        token: element.name+'#kwart_pagina',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    image_tokens.push({
                        token: element.name+'#hele_pagina',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    // Add element without showing token (used for grouping)
                    tokens.push({
                        token: element.name,
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType,
                        children: image_tokens,
                        isShown: false,
                    })
                }

                // These element types have additional tokens. They have the default 'label' token and an additional 'value' token.
                const listOfValueElementTypes = ['checkbox_multiple', 'radio', 'select'];

                // Check if the element type is in the list of element types that have additional tokens.
                if (listOfValueElementTypes.includes(element.elementType)) {
                    let raw_value_tokens = []
                    raw_value_tokens.push({
                        token: element.name+'#waarde',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    // Add element without showing token (used for grouping)
                    tokens.push({
                        token: element.name,
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType,
                        children: raw_value_tokens,
                        isShown: false,
                    })
                }

                if (element.elementType === 'map') {
                    // Add element without showing token (used for grouping)
                    let token = {
                        token: element.name,
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType,
                        children: [],
                        isShown: false,
                        isLoading: false,
                    }
                    if (element.mapSettings.mapLayers[0]) {
                        token.datasource_id = element.mapSettings.mapLayers[0].datasource_id
                    }
                    if (element.mapSettings.featuresMaxSelect && element.mapSettings.featuresMaxSelect > 1) {
                        token.description = 'Er kunnen meer dan 1 features geselecteerd worden op deze kaart. Gebruik de tokens $'+element.name+'.1.kolomnaam$ en $'+element.name+'.2.kolomnaam$ etc als u de overige geselecteerde features wilt gebruiken.';
                    }
                    // Add token
                    tokens.push(token)
                }

                if (element.elementType === 'search') {
                    // Add element without showing token (used for grouping)
                    let token = {
                        token: element.name,
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType,
                        children: [],
                        isShown: false,
                        isLoading: false,
                        searchSettings: element.searchSettings, // Needed for the search token in subforms
                    }
                    if (element.searchSettings.datasourceId) {
                        token.datasource_id = element.searchSettings.datasourceId
                    }
                    if (element.searchSettings.maxValues && element.searchSettings.maxValues > 1) {
                        token.description = 'Er kunnen meer dan 1 items geselecteerd worden. Gebruik de tokens $'+element.name+'.1.kolomnaam$ en $'+element.name+'.2.kolomnaam$ etc als u de overige geselecteerde items wilt gebruiken.';
                    }
                    // Add token
                    tokens.push(token)
                }

                // Add images tokens
                if (element.elementType === 'gps') {
                    let gps_tokens = []
                    gps_tokens.push({
                        token: element.name+'.longitude',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    gps_tokens.push({
                        token: element.name+'.latitude',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    gps_tokens.push({
                        token: element.name+'#qr',
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType
                    })
                    // Add element without showing token (used for grouping)
                    tokens.push({
                        token: element.name,
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType,
                        children: gps_tokens,
                        isShown: false,
                    })
                }

                // All other tokens
                let hideTokenOnElementTypes = ['image', 'subform', 'map', 'search', 'linkedForm', 'gps']
                // Add listOfValueElementTypes tokens to hideTokenOnElementTypes
                hideTokenOnElementTypes.push(...listOfValueElementTypes)

                if (!hideTokenOnElementTypes.includes(element.elementType)) {
                    // Add token
                    tokens.push({
                        token: element.name,
                        label: element.label,
                        elementName: element.name,
                        elementType: element.elementType,
                    })
                }

            }

            return tokens
        },
        getElementsFromSchemaFiltered(schema, includeElementTypes) {
            if (!includeElementTypes) {
                return schema;
            }

            return schema.filter(function(element) {
                return includeElementTypes.includes(element.elementType);
            });
        },
        getElementOptionsKeyed(element) {
            let options = {}
            if (element.options.length) {
                for (let option of element.options) {
                    options[option.value] = option.label
                }
            }
            return options
        },
        getElementByName(schema, elementName) {
            for(let element of schema) {
                if(element.name === elementName) {
                    return element;
                }
            }
            return null;
        },

        replaceTokens(textString, tokens, values, formattedValues = false, formattedValuesSchema = null) {
            let $vm = this
            tokens.forEach(function(tokenObj) {

                if (tokenObj.elementType === 'map') {
                    // Replace all map tokens.
                    textString = $vm.replaceMapFeatureTokens(textString, tokenObj, values)
                }
                else if (tokenObj.elementType === 'subform_metadata') {
                    // Replace all subform metadata tokens
                    textString = $vm.replaceSubformMetadataTokens(textString, tokenObj, values);
                }
                else {
                    // All other 'normal' tokens
                    let dataName = tokenObj.token;
                    let token = '$' + dataName + '$';

                    if (values[dataName]) {
                        // Get raw value
                        let value = values[dataName]
                        // Set formatted value if set
                        if (formattedValues) {
                            // Get element
                            let element = $vm.getElementByName(formattedValuesSchema, dataName)
                            // 'element' can be null in some cases when it doesn't exist anymore.
                            if (element) {
                                // Get formatted value
                                value = $vm.formattedValue(element, values[dataName])
                            }
                        }
                        // Replace value
                        textString = textString.replaceAll(token, value);
                    }
                    else {
                        // Fallback to an empty string when there is no value.
                        textString = textString.replaceAll(token, '');
                    }
                }

            });

            return textString;
        },
        replaceSubformMetadataTokens(textString, tokenObj, values) {
            // Loop over all subform tokens
            let subform_tokens = tokenObj.children;

            for (const subform_token of subform_tokens) {
                // let token = '$' + subform_token.token + '$';
                let subformName = tokenObj.elementName;
                let elementName = subform_token.elementName;
                let metadataName = subform_token.metadataName;
                let token = `${subformName}_metadata.${elementName}.${metadataName}`;

                if (has(values, `${subformName}_metadata.${elementName}.${metadataName}`)) {
                    let value = values[subformName+"_metadata"][elementName][metadataName];
                    // Replace value
                    textString = textString.replaceAll(token, value);
                }
                else {
                    // Fallback to an empty string when there is no value.
                    textString = textString.replaceAll('$'+token+'$', '');
                }
            }
            return textString;
        },
        replaceMapFeatureTokens(textString, token, values) {
            // Create an array of all the possible map tokens for this map element
            let mapTokens = []
            // Add the default static map token
            mapTokens.push('$'+token.elementName+'.name$')
            mapTokens.push('$'+token.elementName+'.status$')
            mapTokens.push('$'+token.elementName+'.longitude$')
            mapTokens.push('$'+token.elementName+'.latitude$')
            // Add the dynamic map tokens from the 'properties.data' object
            if (has(values, [token.elementName, 'features', '0', 'properties', 'data'])) {
                let properties = values[token.elementName].features[0].properties.data
                for (let key in properties) {
                    mapTokens.push('$' + token.elementName + '.' + key + '$')
                }
            }

            // Map element has no value. Remove all map tokens and return the textString
            if (isEmpty(values[token.elementName])) {
                this.removeUnusedTokens(textString, mapTokens);
                return textString;
            }

            let mapValues = values[token.elementName]

            // Replace the static map tokens: 'name', 'status', 'longitude' and 'latitude' tokens
            if (has(mapValues, ['features', '0', 'properties', 'name'])) {
                let feature_name = get(mapValues, ['features', '0', 'properties', 'name']);
                textString = textString.replaceAll('$'+token.elementName+'.name$', feature_name);
            }
            if (has(mapValues, ['features', '0', 'properties', 'status'])) {
                let feature_status = get(mapValues, ['features', '0', 'properties', 'status']);
                textString = textString.replaceAll('$'+token.elementName+'.status$', feature_status);
            }
            if (has(mapValues, ['features', '0', 'geometry', 'coordinates', '0'])) {
                let feature_longitude = get(mapValues, ['features', '0', 'geometry', 'coordinates', '0']);
                textString = textString.replaceAll('$'+token.elementName+'.longitude$', feature_longitude);
            }
            if (has(mapValues, ['features', '0', 'geometry', 'coordinates', '1'])) {
                let feature_latitude = get(mapValues, ['features', '0', 'geometry', 'coordinates', '1']);
                textString = textString.replaceAll('$'+token.elementName+'.latitude$', feature_latitude);
            }

            // Replace the dynamic map tokens that are in the 'properties.data' object
            if (has(mapValues, ['features', '0', 'properties', 'data'])) {
                let properties = mapValues.features[0].properties.data
                for (let key in properties) {
                    let tokenString = '$' + token.elementName + '.' + key + '$'
                    let value = properties[key]
                    textString = textString.replaceAll(tokenString, value);
                }
            }

            this.removeUnusedTokens(textString, mapTokens);
            return textString;
        },
        /**
         * Remove all unused tokens from a text string.
         *
         * @param textString
         * @param tokens
         * @returns {*}
         */
        removeUnusedTokens(textString, tokens = []) {
            // Loop through all tokens and remove them from the textString
            tokens.forEach(function (token) {
                textString = textString.replaceAll('$'+token+'$', '');
            });
            return textString;
        },
        /**
         * Clean an object, remove all __prototype__ properties.
         *
         * @param object
         * @returns {{}}
         */
        objectRemovePrototypeProperties(object) {
            // Make new value without all __prototype__ properties, since this breaks things later on when saving the form.
            let newValues = {}
            Object.getOwnPropertyNames(object).forEach(function (value) {
                newValues[value] = object[value]
            });
            // Return clean values without all the __prototype__ properties.
            return newValues
        },
        /**
         * Convert file values with a single array to a nested array
         *
         * For example:
         * [
         *   {url: '/url/here'}
         * ]
         * will be converted to
         * [
         *   [
         *     {url: '/url/here'}
         *   ]
         * ]
         */
        fileValuesConvertSingleToMultiple(schema, formValues) {
            let $vm = this
            let fileElements = this.getElementsFromSchemaFiltered(schema, ['file','image']);
            fileElements.forEach(function (fileElement){
                // Make sure the element exists in the formValues
                if (_.isPlainObject(formValues) && Object.prototype.hasOwnProperty.call(formValues, fileElement.name)) {
                    // Check if it's a single array
                    if (_.isPlainObject(formValues[fileElement.name][0])) {
                        // Make new value without all __prototype__ properties, since this breaks things later on when saving the form.
                        let objectWithoutPrototype = $vm.objectRemovePrototypeProperties(formValues[fileElement.name][0])
                        // Set new form values
                        formValues[fileElement.name][0] = [objectWithoutPrototype]
                    }
                }
            });
        },
        /**
         * Convert file values with a double array to a single array
         *
         * For example:
         * [
         *   [
         *     {url: '/url/here'}
         *   ]
         * ]
         * will be converted to
         * [
         *   {url: '/url/here'}
         * ]
         */
        fileValuesConvertMultipleToSingle(schema, formValues) {
            let fileElements = this.getElementsFromSchemaFiltered(schema, ['file','image']);
            fileElements.forEach(function (fileElement){
                // Make sure the element exists in the formValues
                if (_.isPlainObject(formValues) && Object.prototype.hasOwnProperty.call(formValues, fileElement.name)) {
                    // Check if it's a double array
                    if (_.isArray(formValues[fileElement.name]) && formValues[fileElement.name].length > 0) {
                        if (_.isArray(formValues[fileElement.name][0]) && formValues[fileElement.name][0].length > 0) {
                            // Set new form values
                            formValues[fileElement.name][0] = formValues[fileElement.name][0][0];
                        }
                    }
                }
            });
        },
        getRegistrationSummary(element, tokens, values) {
            let regText = element.summaryText;
            let replacedSummary = this.replaceTokens(regText, tokens, values, true, this.schema);
            // Return token replaced text with a HTML filter.
            return this.sanitizeHtmlCkeditorSimple(replacedSummary)
        },
        /**
         * Get the metadata values for all subforms.
         *
         * @returns {{}}
         */
        getAllSubformMetadataValues() {
            let subformElements = this.getElementsFromSchemaFiltered(this.myForm.schema, ['subform']);
            let subformMetadata = {};
            // Get the metadata values for each subform.
            for (const subformElement of subformElements) {
                subformMetadata[subformElement.name+'_metadata'] = this.getSubformMetadataValues(subformElement);
            }
            return subformMetadata;
        },
        /**
         * Returns metadata value for a single subform.
         *
         * @example
         * let subformMetaDataValues = [{
         *         totaal: {
         *           sum: 230,
         *           count: 2,
         *         },
         *         aantal: {
         *           sum: 11,
         *           count: 2,
         *         }
         *       }];
         *
         * @param subformElement
         */
        getSubformMetadataValues(subformElement) {
            let metadataValues = {};
            let subformSchema = subformElement.subform.schema;
            // Make sure the subform values are not empty
            if (!isEmpty(this.myForm.values[subformElement.name])) {
                let subformValues = this.myForm.values[subformElement.name];
                // Get all elements inside the subform that are able to sum-able
                // (for now we only make a sum of the 'calculation' and 'number' elements).
                let sumElements = this.getElementsFromSchemaFiltered(subformSchema, ['calculation', 'number']);
                // Loop over all subform values
                if (!isEmpty(subformValues)) {
                    // Loop over all sum-able elements
                    for (const sumElement of sumElements) {
                        // Create empty metadata values object
                        metadataValues[sumElement.name] = {
                            sum: 0,
                            count: 0,
                        };
                        // Loop over all subform registrations
                        for (const subformRegistration of subformValues) {
                            // Make sure the element has a value and is it's a number
                            if (subformRegistration[sumElement.name] && isFinite(subformRegistration[sumElement.name])) {
                                // Add sum
                                metadataValues[sumElement.name].sum += parseFloat(subformRegistration[sumElement.name]);
                                // Add count
                                metadataValues[sumElement.name].count++;
                            }
                        }
                        // Round sum values 2 after the decimal
                        metadataValues[sumElement.name].sum = Math.round(metadataValues[sumElement.name].sum * 100) / 100;
                    }
                }
            }
            return metadataValues;
        },
    }
};