<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type} qr-code-scanner-wrapper`"
      :data-type="context.type"
  >

    <div class="qr-code-scanner-inner">

      <div :id="scanner_unique_id" class="qr-code-full-region"></div>
      <v-skeleton-loader v-if="scannerLoading" type="image"></v-skeleton-loader>

      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>

      <v-btn v-if="!scannerIsActive && !scannerLoading" depressed outlined color="primary" block @click="startScanner">Start scanner</v-btn>
      <v-btn v-if="scannerIsActive && !scannerLoading" depressed outlined color="primary" class="mt-3" block @click="stopScanner">Stop scanner</v-btn>

      <div v-if="model.length" class="qr-code-result">
        <h4>Resultaat</h4>
        <code>{{ context.model}}</code>
      </div>

    </div>

  </div>
</template>

<script>
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode";
import {uuid} from "vue-uuid";

export default {
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      scanner_unique_id: 'qr-scanner-' + uuid.v4(),
      error: '',
      html5QrcodeScanner: null,
      scannerLoading: false,
      scannerIsActive: false,
      config: {
        fps: 10,
        qrbox: 350,
        rememberLastUsedCamera: false, // Prevents the scanner from automatically starting the scanner
        videoConstraints: {
          facingMode: 'environment',
          width: 2000,
          frameRate: 10,
        },
      },
      formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ],
    }
  },
  mounted() {
    this.context.model = '';
  },
  computed: {
    model() {
      return this.context.model
    },
  },
  methods: {
    async startScanner() {
      this.html5QrcodeScanner = new Html5Qrcode(
          this.scanner_unique_id,
          {}
      );

      this.error = '';
      this.scannerLoading = true;

      try {
        // Start the scanner
        await this.html5QrcodeScanner.start(
            {facingMode: "environment"},
            this.config,
            this.onScanSuccess,
            this.onError
        );
        // Set scanner to active
        this.scannerIsActive = true;

      } catch (errorMessage) {
          this.onError(errorMessage);
      }

      this.scannerLoading = false;
    },
    stopScanner() {
      this.html5QrcodeScanner.stop();
      this.scannerIsActive = false;
    },
    onScanSuccess(decodedText, decodedResult) {
      this.context.model = decodedText;
      console.log(decodedText, decodedResult);
      // Stop scanning
      this.stopScanner();
    },
    onError(errorMessage, error) {
      // Skip this error
      if (
          errorMessage.includes('No barcode or QR code detected.') ||
          errorMessage.includes('No MultiFormat Readers were able to detect the code')
      ) {
        return;
      }

      console.error(errorMessage);
      console.error(error)

      // Check if string contains 'NotAllowedError: Permission dismissed'
      if (
          errorMessage.includes('NotAllowedError: Permission dismissed') ||
          errorMessage.includes('NotAllowedError: Permission denied')
      ) {
        this.error = 'Cameratoegang geweigerd. Sta cameratoegang toe.';
      }
      else if (errorMessage.includes('NotFoundError: No video device found')) {
        this.error = 'Geen camera gevonden. Sluit een camera aan.';
      }
      else {
        this.error = errorMessage;
      }

    }
  },
}
</script>

<style lang="scss">
.qr-code-scanner-wrapper {
  padding-bottom: 20px;
}
.qr-code-scanner-inner {
  max-width: 600px;
  margin: 0 auto;
}
.qr-code-result {
  margin-top: 20px;
}
.qr-code-result code {
  display: block;
  white-space: pre-wrap;
  max-height: 300px;
  overflow: auto;
}
</style>