import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import localForage from 'localforage';
import { uuid } from 'vue-uuid';
import auth from './auth';
import axios from "axios";
import router from '@/plugins/routes';
import {loader} from "@/plugins/loader";
import {storeCache} from "@/plugins/storeCache";

Vue.use(Vuex);

const state = {
    title: 'My state title',
    values: {},
    forms: [],
    folders: [],
    favorites: {
        elements: [],
    },
    updateAvailable: false,
    unsyncedRegistrations: [],
    fieldValues: {},
    pwaInstallPromptLastShown: 0,
};

const getters = {
    getFormByID: (state) => (form_name) => {
        return state.forms.find(({ form_id }) => form_id === form_name);
    },
    getFormByGuestID: (state) => (guest_id) => {
        return state.forms.find(({ form_guest_id }) => form_guest_id === guest_id);
    },
    getFolderByID: (state) => (folder) => {
        return state.folders.find(({ folder_id}) => folder_id === folder);
    },
    getFieldValue: (state) => (field_uuid) => {
        if (state.fieldValues[field_uuid]) {
            return state.fieldValues[field_uuid].value;
        }
        else {
            return undefined;
        }
        // return state.fieldValues.find(({ uuid }) => uuid === field_uuid);
    },
    getRegistrationByFillToken: (state) => (token) => {
        return state.unsyncedRegistrations.find(({ fill_token }) => fill_token === token);
    },
};

const actions = {
    async createForm ( { commit, state }, {form_name, folder_id, form_type, schema}) {
        let client_id = state.auth.currentClient;
        const form = {
            form_id: uuid.v4(),
            form_name: form_name,
            form_type: form_type,
            folder_id: folder_id,
            client_id: client_id,
            schema: schema,
            rules: [],
            values: {},
            email_settings: [],
        };
        if (form_type === 'workflow') {
            // Set default registration phase
            form.registration_phase = "1";
        }

        let response = await axios.post('api/form', form);

        await commit('createForm', {form: response.data});

        router.push({ name: 'form_edit', params: {form_id: response.data.form_id} })

    },

    async createDuplicateFromForm ( { commit }, {oldForm}) {
        // Delete old fields that are unique per form (prevent faulty information/links)
        delete oldForm._id;
        delete oldForm.created_at;
        delete oldForm.updated_at;
        delete oldForm.form_id;
        delete oldForm.revision_id;
        delete oldForm.published_at; // Set new copy to be unpublished by default

        oldForm.form_id = uuid.v4(); // Generate new form_id
        oldForm.form_name = 'Kopie van ' + oldForm.form_name; // Set a different name for the new form

        let response = await axios.post('api/form', oldForm);

        await commit('createForm', {form: response.data});

        return response.data.form_id;
    },

    async editForm ( { commit }, {form}) {
        let response = await axios.patch('api/form/'+form.form_id, form);

        commit('updateForm', {form: response.data});
    },
    async createOrUpdateSavedRegistration({commit}, {registration}) {
        // Add form to saved registrations
        commit('createOrUpdateSavedRegistration', {form: registration});
    },
    async registrationCompleted({commit}, {registration}) {
        commit('registrationCompleted', {form: registration});
        commit('clearOldFieldValues');
        commit('storeInputValues', {form: registration});
    },
    async registrationCompletedByGuest({commit}, {registration}) {
        commit('clearOldFieldValues');
        commit('storeInputValues', {form: registration});
    },
    async guestFillForm({commit}, {form, guestId}) {
        // Get basis registration info
        let registration_info = {
            registration_id: form._id ?? '',
            fill_token: form.fill_token ?? '',
            client_id: form.client_id ?? '',
            form_name: form.form_name ?? '',
            form_id: form.form_id ?? '',
            form_type: form.form_type ?? '',
        }

        try {
            // Send registration to server
            let response = await axios.post('api/guest/form/' + guestId, form);
            commit('clearOldFieldValues');
            commit('storeInputValues', {form: response.data});
            return true;
        } catch (error) {
            console.error('Error during guest form submission:', error);
            Vue.prototype.$rollbar.error('Error sending guest form to server', registration_info);
            return false;
        }
    },
    async getUserFolderPublishedForms({commit}, {folder_id}) {
        // Fetch new oauth tokens
        let response = await axios.get('api/folder/' + folder_id + '/forms/user');
        commit('setForms', {forms: response.data});
    },
    async getFolders({commit, state}) {
        let response = await axios.get('/api/client/'+ state.auth.currentClient +'/folders');
        commit('setFolders', {folders: response.data});
    },
    async getFoldersFiller({commit, state}) {
        let folders = [];
        let userClients = state.auth.user.clients;
        for (let i = 0; i < userClients.length; i++) {
            let response = await axios.get('/api/client/'+ userClients[i].id +'/folders');
            folders = folders.concat(response.data);
        }
        commit('setFolders', {folders: folders});
    },
    async getFoldersUser({commit, state}) {
        let response = await axios.get('/api/client/'+ state.auth.currentClient +'/folders/user');
        commit('setFolders', {folders: response.data});
    },
    async getFoldersFillerUser({commit, state}) {
        let folders = [];
        let userClients = state.auth.user.clients;
        for (let i = 0; i < userClients.length; i++) {
            let response = await axios.get('/api/client/'+ userClients[i].id +'/folders/user');
            folders = folders.concat(response.data);
        }
        commit('setFolders', {folders: folders});
    },
    async createFolder ( { commit, state }, {folder_name, hidden}) {
        let client_id = state.auth.currentClient;
        const folder = {
            folder_name: folder_name,
            client_id: client_id,
            hidden: hidden,
        };

        let response = await axios.post('api/folder', folder);

        if (!response.data) {
            throw new Error(response.toString());
        }

        await commit('createFolder', {folder: response.data});

        return response.data;
    },
    async setValues({commit}, {form_id, values}) {
        commit('updateValues', {'form_id': form_id, 'values': values});
    },

    async addFavoriteElement({commit, dispatch}, {newFavorite}) {
        delete newFavorite.name;
        newFavorite.uuid = uuid.v4();

        // let response = await axios.post('api/favorite/elements', newFavorite);
        await commit('addFavoriteElement', {favorite: newFavorite});

        return dispatch('getFavoriteElements');
    },

    async removeFavoriteElement({commit}, {favoriteElementId}) {
        // let response = await axios.post('api/favorite/elements', newFavorite);
        await commit('removeFavoriteElement', {favoriteElementId: favoriteElementId});
    },

    async getFavoriteElements({state}) {
        // let response = await axios.get('api/favorite/elements');
        //
        // await commit('setFavoriteElements', {favorites: response.data});

        return state.favorites.elements;
    },
    async syncSavedRegistrations({commit}) {
        // Sync the saved registration
        await axios.post('api/saved-registrations-sync', [])
            .then(response => {
                if (response && response.status === 201) {
                    // Success, replace our entire saved registrations with the version of the server
                    commit('replaceAllSavedRegistrations', {
                        registrations: response.data.registrations
                    });
                }
            });
    },
};

const mutations = {
    updateSchema(state, {form_id, schema}) {
        let index = state.forms.findIndex(e=> e.form_id === form_id);
        state.forms[index].schema = schema;
    },
    updateRules(state, {form_id, rules}) {
        let index = state.forms.findIndex(e=> e.form_id === form_id);
        state.forms[index].rules = rules;
    },
    updateValues(state, {form_id, values}) {
        let index = state.forms.findIndex(e=> e.form_id === form_id);
        state.forms[index].values = values;
    },
    updateElement(state, {form_id, element }) {
        let index = state.forms.findIndex(e=> e.form_id === form_id);
        let elementIndex = state.forms[index].schema.findIndex(e=> e.uuid === element.uuid);

        Vue.set(state.forms[index].schema, elementIndex, element);
    },
    addElementToSchema(state, {id, element}) {
        let form = state.forms.find(({ form_id }) => form_id === id);
        form.schema.push(element);
    },
    removeElementFromSchema(state, {id, element}) {
        let form = state.forms.find(({ form_id }) => form_id === id);
        let elementIndex = form.schema.findIndex(e=> e.uuid === element.uuid);
        form.schema.splice(elementIndex, 1);

    },
    setForms(state, {forms}) {
        let formattedForms = [];

        for(var i = 0; i < forms.length; i++) {
            formattedForms[i] = forms[i].form;
            if (Array.isArray(forms[i].form.values)) {
                formattedForms[i].values = Object.fromEntries(forms[i].form.values);
            }
        }
        state.forms = formattedForms;
    },
    createForm(state, {form}) {
        state.forms.push(form);
    },
    updateForm(state, {form}) {
        let index = state.forms.findIndex(e=> e.form_id === form.form_id);
        state.forms[index] = form;
    },
    setFolders(state, {folders}) {
        state.folders = folders;
    },
    createFolder(state, {folder}) {
        state.folders.push(folder);
    },
    createOrUpdateSavedRegistration(state, {form}) {
        // Set changed date
        let date_now = new Date();
        form.registration_updated_at = date_now.toISOString()
        // Set flag to indicate its only local
        form.unsynced = true
        // Check if registration already exists
        let index = state.unsyncedRegistrations.findIndex(e=> e.fill_token === form.fill_token);
        if (index > -1) {
            // Update existing saved registration
            state.unsyncedRegistrations[index] = form;
        }
        else {
            // Add new saved registration
            state.unsyncedRegistrations.push(form);
        }
    },
    replaceSavedRegistration(state, {form}) {
        // Check if registration already exists
        let index = state.unsyncedRegistrations.findIndex(e=> e.fill_token === form.fill_token);
        if (index > -1) {
            // Replace existing saved registration
            state.unsyncedRegistrations[index] = form;
        }
    },
    replaceAllSavedRegistrations(state, {registrations}) {
        Vue.set(state, 'unsyncedRegistrations', registrations)
    },
    deleteAllSavedRegistrations(state) {
        // Empty all saved registrations
        Vue.set(state, 'unsyncedRegistrations', [])
    },
    deleteSavedRegistration(state, {form}) {
        let index = state.unsyncedRegistrations.findIndex(e=> e === form);
        state.unsyncedRegistrations.splice(index, 1)
    },
    registrationCompleted(state, {form}) {
        // Remove all unsynced registrations with the same fill_token
        state.unsyncedRegistrations = state.unsyncedRegistrations.filter(e => e.fill_token !== form.fill_token);
    },
    storeInputValues(state, {form}) {
        for (let i = 0; i < form.schema.length; i++) {
            if (form.schema[i].rememberInput) {
                let uuid = form.schema[i].uuid;
                let fieldName = form.schema[i].name;

                if (form.values[fieldName]) {
                    console.log('Storing field value for field ' + fieldName + '. Value: ' + form.values[fieldName]);
                    state.fieldValues[uuid] = {
                        value: form.values[fieldName],
                        timestamp: new Date(),
                    }
                }
                else {
                    console.log('Deleting field value for field ' + fieldName + '.');
                    delete state.fieldValues[uuid];
                }
            }
        }
    },
    clearOldFieldValues(state) {
        // If it's not set or not an object we'll ignore it, no fieldValues set.
        if (!state.fieldValues || typeof state.fieldValues !== 'object') {
            return;
        }

        // Deletes field values older than 6 months.
        var oldDate = new Date();
        oldDate.setMonth(oldDate.getMonth() - 6);

        // Clears field values older than 6 months from the store
        for (const [fieldId, value] of Object.entries(state.fieldValues)) {
            // Get date 6 months prior
            if (!('timestamp' in value) || value.timestamp < oldDate) {
                // Is older or has no timestamp: EXTERMINATE!
                delete state.fieldValues[fieldId];
            }
        }
    },
    pwaInstallPromptIsShown(state) {
        let timestamp = Math.floor(Date.now() / 1000)
        state.pwaInstallPromptLastShown = timestamp
    },

    setUpdateAvailable(state, registration) {
        state.updateAvailable = registration;
    },
    setNoUpdateAvailable(state) {
        state.updateAvailable = false;
    },
    addFavoriteElement(state, {favorite}) {
        if (state.favorites.elements && Array.isArray(state.favorites.elements)) {
            state.favorites.elements.push(favorite);
        }
        else {
            state.favorites.elements = [favorite];
        }
    },
    removeFavoriteElement(state, {favoriteElementId}) {
        let favElementIndex = state.favorites.elements.findIndex(({ uuid }) => uuid === favoriteElementId);
        state.favorites.elements.splice(favElementIndex, 1);
    },
    setFavoriteElements(state, {favorites}) {
        state.favorites.elements = favorites;
    }
};

const methods = {};

const vuexLocalStorage = new VuexPersist({
    storage: localForage, // or window.sessionStorage or localForage
    asyncStorage: true,
    //supportCircular: true,
})

export default new Vuex.Store({
    strict: true,
    state,
    modules: {
        auth,
        loader,
        storeCache,
    },
    plugins: [vuexLocalStorage.plugin],
    getters,
    mutations,
    actions,
    methods,
});
