<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type} computed-wrapper`"
      :data-type="context.type"
  >

    <input readonly type="text" :name="context.name" v-model="model" :id="context.id" />

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";

export default {
  mixins: [
    eformHelpers,
  ],
  data: () => ({
    elementToWatch: 'qrCodeScanner1',
  }),
  props: {
    context: {
      type: Object,
      required: true
    },
    values: {
      type: Object,
      default: () => ({}), // Default value is an empty object
    },
    schema: {
      type: Array,
      default: () => ([]), // Default value is an empty array
    },
  },
  watch: {
    // Watch a specific field in the values object, this is a dynamic variable, for example text1
    elementValueToWatch(newValue) {
      // Prevent watching our own computed field, otherwise we will end up in an infinite loop
      if (this.context.name === this.elementToWatch) {
        return;
      }

      // Apply the parser
      if (this.context.attributes.parserType === 'parseSchrederFixtureQrCodeLampAmount') {
        this.context.model = this.parseSchrederFixtureQrCode(newValue, 14);
      } else if (this.context.attributes.parserType === 'parseSchrederFixtureQrCodeColorTemp') {
        this.context.model = this.parseSchrederFixtureQrCode(newValue, 17);
      }
    },
  },
  computed: {
    elementValueToWatch() {
      return this.values[this.elementToWatch];
    },
    model() {
      return this.context.model
    },
  },
  methods: {
    /**
     * This is an example of a scanned QR code from a Schreder fixture (it is separated with a \n). I want to extract the value of key '14'.
     *
     * S/N:108452120001
     * 1:SCHREDER
     * 2:TECEO S
     * 3:TECEOS-024163_D2A4_AKZO900GS
     * 4: 08/2020
     * 5:OP1234566
     * 6:022/400
     * 7:SO012643
     * 8:Class II
     * 9:3*MOV_10K
     * 10:IP67
     * 11:IK08
     * 12:Pf>0,92
     * 13:ta 35°C
     * 14:24L
     * 15:XP-G3-S5
     * 16:5102BL
     * 17:4000K
     * 18:Min.70
     * 19:2980lm
     * 20:21,7W
     * 21:20,3W
     * 22:147lm/W
     * 23:1520lm
     * 24:12,6W
     * 25:100000Hrs
     * 26:90%
     * 27:230Vac
     * 28:50Hz
     * 29:D2A4
     * 30:420mA
     * 31:CusDim
     * 32:Yes
     * 33:25%
     * 34:DALI 2
     * 35:Yes
     * 36:DC emergency
     * 37:OSRAM
     * 38:DRIVER Xitanium LP 40W 0.2-0.7A S1 230V S175 sXt 1-10V
     * 39:AKZO900GS
     * 40:10,5m
     * 41:H07RN-F
     * 42:3G1.5²
     * 43:UNIWO60(FIXED-VER-10)
     * 44:10,5kg
     * 45:585,00x108,00x274,00
     * 46:0,12 m²
     * 47:NEMA_7P
     * 48:CusDim,TEL_Mi
     * 49:TEL_Mi
     * 50:RaTr
     */
    parseSchrederFixtureQrCode(valueOtherElement, extract_key) {
      const lines = valueOtherElement.split('\n');
      const result = {};

      for (const line of lines) {
        const [key, value] = line.split(':');
        result[key] = value;
      }

      return result[extract_key];
    }
  }
}
</script>

<style lang="scss" scoped>
</style>