<template>
  <div>

    <v-container>
      <v-row>
        <v-col class="white rounded-lg py-3 px-4 widget-wrapper">
          <div class="widget-actions-prepend">
            <v-icon class="handle">mdi-arrow-up-down</v-icon>
            <v-icon @click.stop="showModal">mdi-pencil-outline</v-icon>
            <v-icon color="warning" v-if="!datanameIsUnique">mdi-alert</v-icon>
          </div>
          <div class="widget-form-element">
            <!-- If element.component is set, then create a component of that type. Most likely this will be h1, h2, h3 -->
            <template v-if="element.elementType === 'header'">
              <component :is="element.component">
                {{ element.label }}
              </component>
            </template>
            <template v-else-if="element.elementType === 'staticText'">
              <div v-html="sanitizeHtmlCkeditorSimple(element.staticText)"></div>
            </template>
            <template v-else-if="element.elementType === 'imageTag'">
              <img v-if="element.image && element.image.length" :src="element.image[0].url" :width="element.width" :height="element.height" alt="">
            </template>
            <template v-else-if="element.elementType === 'subform'">
              <FormulateInput
                  :key="element.uuid"
                  v-bind="element"
                  :is-admin-mode="true"
              />
            </template>
            <template v-else-if="element.elementType === 'linkedForm'">
              <FormulateInput
                  :key="element.uuid"
                  v-bind="element"
                  :is-admin-mode="true"
              />
            </template>
            <!-- Show Vue Formulate component -->
            <template v-else>
              <FormulateInput
                  :disabled="disabledProp"
                  :key="element.uuid"
                  :is-admin-mode="true"
                  v-bind="element"
              />
            </template>
          </div>
          <div class="widget-actions-append">
            <v-icon @click="$emit('formRemoveElement', element)" color="error">mdi-trash-can</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>


    <v-dialog v-model="modal" content-class="modal-element-edit" max-width="800" scrollable persistent no-click-animation @click:outside="closeModal">
      <v-card>
        <v-card-title class="headline">
          Bewerk {{ element.label }}
        </v-card-title>
        <v-card-text>
          <FormulateForm v-model="EformElementEditValues">

            <v-alert
                class="my-2"
                v-if="!datanameIsUnique"
                dense
                prominent
                text
                type="warning"
            >
              Datanaam is niet uniek. Kies een andere datanaam.
            </v-alert>

            <!-- Add settings for component. Which most likely will be h1, h2, h3 -->
            <template v-if="element.elementType == 'header'">
              <FormulateInput
                  type="text"
                  v-model="element.label"
                  label="Koptekst"
                  validation="bail|required"
                  error-behavior="live" />
              <FormulateInput
                  v-model="element.component"
                  :options="{h1: 'Tekstgrootte 1', h2: 'Tekstgrootte 2', h3: 'Tekstgrootte 3', h4: 'Tekstgrootte 4'}"
                  type="select"
                  label="Koptekst grootte"
              />
            </template>

            <template v-else-if="element.elementType == 'staticText'">
              <FormulateInput
                  type="ckeditor"
                  editor-preset="simple"
                  label="Vaste tekst"
                  help="Deze tekst is niet aanpasbaar door de invuller en kan gebruikt worden om bijvoorbeeld uitleg te geven."
                  validation="optional"
                  :debounce="100"
                  v-model="element.staticText"
                  error-behavior="live" />
            </template>

            <!-- Add settings for component. Which most likely will be h1, h2, h3 -->
            <template v-else-if="element.elementType == 'imageTag'">
              <FormulateInput
                  type="image"
                  name="image_upload"
                  v-model="element.imageUpload"
                  label="Upload een afbeelding"
                  help="Toegestane bestandsextenties: jpg jpeg png gif"
                  validation="mime:image/jpeg,image/png,image/gif"
                  upload-url="/api/file-upload/imagetag"
              />
              <FormulateInput
                  type="number"
                  v-model="element.width"
                  label="Breedte afbeelding"
                  validation="bail|optional"
                  error-behavior="live" />
              <FormulateInput
                  type="number"
                  v-model="element.height"
                  label="Hoogte afbeelding"
                  validation="bail|optional"
                  error-behavior="live" />
            </template>

            <!-- Add settings for component. Which most likely will be h1, h2, h3 -->
            <template v-else-if="element.elementType == 'staticFile'">
              <FormulateInput
                  type="file"
                  name="file_upload"
                  v-model="element.fileUpload"
                  label="Upload een bestand"
                  help="Toegestane bestandsextenties: pdf docx txt jpg jpeg png gif"
                  validation="mime:application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png,image/gif"
                  upload-url="/api/file-upload/static-file"

              />
            </template>

            <!-- Show settings for Vue Formulate component -->
            <template v-else>

              <!-- Global settings, used for all elements -->
              <v-row class="pt-5 pb-7">
                <v-col>
                  <FormulateInput
                      type="text"
                      v-model="element.label"
                      label="Label"
                      validation="bail|required"
                      :debounce="100"
                      error-behavior="live" />
                  <template v-if="element.elementType !== 'linkedForm'">
                    <FormulateInput
                        type="checkbox"
                        :debounce="100"
                        v-model="validation.required"
                        label="Verplicht veld" />
                  </template>

                  <FormulateInput
                      type="text"
                      v-model="element.help"
                      :debounce="100"
                      help="Wordt onder het veld getoond. Te gebruiken als invulhulp voor de gebruiker."
                      label="Helptekst" />

                  <template v-if="element.elementType !== 'linkedForm' && element.elementType !== 'gps'">
                    <div class="formulate-input">
                      <label class="formulate-input-label">Standaard waarde</label>
                      <div class="formulate-input-help formulate-input-help--after">U kunt de standaard waarde invullen in het formulier zelf. Als 'Waardes onthouden' aangevinkt is, dan wordt de onthouden waarde gebruikt in plaats van de standaard waarde.</div>
                    </div>
                  </template>

                  <template v-if="['text', 'email', 'textarea', 'checkbox_single', 'checkbox_multiple', 'radio', 'select', 'number'].includes(element.elementType)">
                    <FormulateInput
                        v-model="element.rememberInput"
                        type="checkbox"
                        label="Waardes onthouden"
                        help="De ingevulde waardes blijven onthouden per apparaat. Wanneer een gebruiker het formulier opent wordt de laatst waarde automatisch ingevuld."
                    />
                  </template>

                </v-col>
                <v-col>
                  <FormulateInput
                      v-model="advancedEdit"
                      type="checkbox"
                      label="Geavanceerd"
                  />
                  <FormulateInput
                      v-if="advancedEdit"
                      type="text"
                      v-model="element.name"
                      label="Datanaam"
                      help="Dit veld wordt gebruikt om de waardes te groeperen in het registratie overzicht. Wordt ook gebruikt als e-mail placeholder. Alleen kleine letters a-z, cijfers en underscore _ karakters zijn toegestaan."
                      validation="bail|required|matches:/^[a-z0-9_]+$/"
                      error-behavior="live"
                      @blur-context="datanameChanged"
                  />
                </v-col>
              </v-row>

              <!-- File and image component -->
              <template v-if="element.elementType === 'image'">
                <FormulateInput
                    v-model="element.useOnlyCamera"
                    type="checkbox"
                    label="Alleen fotos vanaf camera maken"
                    help="Vink dit veld aan als de gebruiker alleen fotos mag uploaden die direct vanaf de camera komen."
                />
              </template>
              <template v-if="element.elementType === 'image' || element.elementType === 'file'">
                <FormulateInput
                    v-model="element.fileDoRename"
                    type="checkbox"
                    label="Hernoem het bestand"
                    help="Vink dit veld aan als u het bestand wilt hernoemen nadat het geupload is."
                />

                <FormulateInput
                    v-if="element.fileDoRename"
                    v-model="element.fileNewName"
                    label="Bestandsnaam"
                    type="text"
                    validation="required"
                    help="Dit veld ondersteund tokens. Vul een bestandsnaam in zonder bestandsextentie."/>

                <eform-tokens
                    v-if="element.fileDoRename"
                    :tokens="this.getTokensFromSchemaFilterInclude(form.schema, ['text', 'textarea', 'number', 'email', 'date', 'time', 'datetime', 'checkbox_single', 'radio', 'select'])" />

              </template>

              <!-- Date/time/datetime component -->
              <template v-if="['date', 'time', 'datetime'].includes(element.elementType)">
                <eform-element-edit-date-default-now :element="element"></eform-element-edit-date-default-now>
              </template>

              <!-- Text component -->
              <!-- Also trigger this when element.type is undefined because the default component is 'text' -->
              <template v-if="element.elementType === undefined || element.elementType === 'text' || element.elementType === 'email'">
                <eform-element-edit-text :element="element" @addOrUpdateValidationRule="addOrUpdateValidationRule($event)" @removeValidationRule="removeValidationRule($event)"></eform-element-edit-text>
              </template>

              <!-- Textarea component -->
              <template v-if="element.elementType === 'textarea'">
                <eform-element-edit-textarea :element="element" @addOrUpdateValidationRule="addOrUpdateValidationRule($event)" @removeValidationRule="removeValidationRule($event)"></eform-element-edit-textarea>
              </template>

              <!-- Checkbox & radio component, but only when element.options is set, because we don't need this on single checkboxes -->
              <template v-if="(element.type === 'checkbox' || element.type === 'radio' || element.type === 'select') && element.hasOwnProperty('options')">
                <eform-element-edit-box :element="element" :advanced-edit="advancedEdit"></eform-element-edit-box>
              </template>

              <!-- Number component -->
              <template v-if="element.elementType === 'number'">
                <eform-element-edit-number
                    @getValidationRuleIndex="getValidationRuleIndex($event)"
                    @addOrUpdateValidationRule="addOrUpdateValidationRule($event)"
                    @removeValidationRule="removeValidationRule($event)"
                    :element="element">
                </eform-element-edit-number>
              </template>

              <!-- Map component -->
              <template v-if="element.elementType === 'map'">
                <eform-element-edit-map :element="element" :form="form"></eform-element-edit-map>
              </template>
              <!-- Map component -->
              <template v-if="element.elementType === 'gps'">
                <eform-element-edit-gps
                    :element="element"
                    :form="form"
                    :is-admin-mode="true"
                ></eform-element-edit-gps>
              </template>
              <!-- Search component -->
              <template v-if="element.elementType === 'search'">
                <eform-element-edit-search :element="element"></eform-element-edit-search>
              </template>

              <!-- Signature component -->
              <template v-if="element.elementType === 'signature'">
                <eform-element-edit-signature :element="element"></eform-element-edit-signature>
              </template>

              <template v-if="element.elementType == 'calculation'">
                <eform-element-edit-calculation :element="element" :schema="form.schema"></eform-element-edit-calculation>
              </template>

              <template v-if="element.elementType == 'computed'">
                <eform-element-edit-computed :element="element" :schema="form.schema"></eform-element-edit-computed>
              </template>

              <template v-if="element.elementType == 'stateTransition'">
                <eform-element-edit-state-transition :form_id="form.form_id" :element="element" :schema="form.schema"></eform-element-edit-state-transition>
              </template>

              <!-- Number formatter -->
              <template v-if="element.elementType === 'number' || element.elementType === 'radio' || element.elementType === 'select' || element.elementType === 'calculation'">
                <eform-element-edit-number-formatter :element="element"></eform-element-edit-number-formatter>
              </template>

              <template v-if="element.elementType == 'subform'">
                <v-row>
                  <v-col>
                    <FormulateInput
                        type="text"
                        v-model="element.registrationLabel"
                        label="Registratie label"
                        hint="Bijvoorbeeld: Registratie, subformulier"
                        :help="'Dit label wordt gebruikt voor ingeklapte registraties en de registratie toevoegen/bewerken/verwijderen knoppen. Achter de knoptekst wordt automatisch \'toevoegen\' of \'bewerken\' geplaatst.'"
                        validation="bail|required|"
                        error-behavior="live" />
                  </v-col>
                  <v-col>
                    <div class="formulate-input">
                      <label class="formulate-input-label">Voorbeeld registratielabel:</label>
                      <v-btn
                          color="primary"
                          outlined
                          class="my-1 white--text mx-1"
                      small

                      >
                        {{element.registrationLabel }} toevoegen
                      </v-btn>
                      <br />
                      <v-btn
                          color="error"
                          class="my-1 white--text mx-1"
                          small
                      >
                        Verwijder {{element.registrationLabel }}
                      </v-btn>
                    </div>

                  </v-col>
                </v-row>


                <FormulateInput
                    type="ckeditor"
                    editor-preset="registrationLabel"
                    label="Samenvattings tekst registratie"
                    :help="'Deze kan d.m.v. tokens (zie hieronder voor opties) worden opgesteld en wordt weergeven als titel voor elke registratie'"
                    validation="optional"
                    :debounce="100"
                    v-model="element.summaryText"
                    error-behavior="live" />

                <eform-tokens :show_subform_index="true" :tokens="this.getTokensFromSchemaFilterInclude(element.subform.schema, ['subform_index', 'text', 'textarea', 'number', 'email', 'date', 'time', 'datetime', 'checkbox_single', 'checkbox_multiple', 'radio', 'select', 'calculation'])"></eform-tokens>
              </template>

              <template v-if="element.elementType === 'linkedForm'">
                <v-row>
                  <eform-element-edit-linked-form :element="element"></eform-element-edit-linked-form>
                </v-row>
              </template>

            </template>

          </FormulateForm>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="background-color">
          <v-btn
              color="secondary"
              outlined
              @click="closeModal"
              :disabled="isUploading"
          >
            Sluit
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              fab
              icon
              @click="addToFavorites">
            <v-icon>mdi-heart-outline</v-icon></v-btn>
<!--          <v-btn-->
<!--              color="blue darken-1"-->
<!--              text-->
<!--              @click="modal = false"-->
<!--          >-->
<!--            Save-->
<!--          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import EformElementEditText from "@/components/EformElementEditTypes/EformElementEditText";
import EformElementEditTextarea from "@/components/EformElementEditTypes/EformElementEditTextarea";
import EformElementEditBox from "@/components/EformElementEditTypes/EformElementEditBox";
import EformElementEditNumber from "@/components/EformElementEditTypes/EformElementEditNumber";
import EformElementEditMap from "@/components/EformElementEditTypes/EformElementEditMap";
import EformElementEditGps from "@/components/EformElementEditTypes/EformElementEditGps";
import EformElementEditLinkedForm from "@/components/EformElementEditTypes/EformElementEditLinkedForm";
import EformElementEditSearch from "@/components/EformElementEditTypes/EformElementEditSearch";
import EformElementEditDateDefaultNow from "@/components/EformElementEditTypes/EformElementEditDateDefaultNow";
import EformElementEditSignature from "@/components/EformElementEditTypes/EformElementEditSignature";
import EformElementEditCalculation from "@/components/EformElementEditTypes/EformElementEditCalculation";
import EformElementEditComputed from "@/components/EformElementEditTypes/EformElementEditComputed.vue";
import eformHelpers from "@/mixins/eformHelpers";
import EformTokens from "@/components/EformTokens";
import formulateComplexFileData from "@/mixins/formulateComplexFileData";
import EformSanitzeHtmlHelpers from "@/mixins/eformSanitzeHtmlHelpers";
import _ from "lodash";
import EformElementEditNumberFormatter from "@/components/EformElementEditTypes/EformElementEditNumberFormatter.vue";
import EformElementEditStateTransition from "@/components/EformElementEditTypes/EformElementEditStateTransition.vue";

export default {
  name: 'EformElementEdit',
  components: {
    EformElementEditStateTransition,
    EformElementEditNumberFormatter,
    EformTokens,
    EformElementEditText,
    EformElementEditTextarea,
    EformElementEditBox,
    EformElementEditNumber,
    EformElementEditMap,
    EformElementEditGps,
    EformElementEditLinkedForm,
    EformElementEditSearch,
    EformElementEditDateDefaultNow,
    EformElementEditSignature,
    EformElementEditCalculation,
    EformElementEditComputed
  },
  mixins: [
    eformHelpers,
    formulateComplexFileData,
    EformSanitzeHtmlHelpers,
  ],
  computed: {
    // Return elements validation or an empty array
    elementValidation: function () {
      return this.element.validation || [];
    },
    isUploading: function () {
      // Check file upload
      if (
          _.has(this.element, 'imageUpload') &&
          !_.isEmpty(this.element.imageUpload) &&
          !_.isEmpty(this.element.imageUpload.files) &&
          this.element.imageUpload.files[0].path === false
      ) {
        return true;
      }
      // Check image upload
      if (
          _.has(this.element, 'fileUpload') &&
          !_.isEmpty(this.element.fileUpload) &&
          !_.isEmpty(this.element.fileUpload.files) &&
          this.element.fileUpload.files[0].path === false
      ) {
        return true;
      }
      // No files are being uploaded.
      return false;
    }
  },
  watch: {
    'element.label': {
      handler(value) {
        // Automatically change 'element.name' (the dataname) when the label changes AND the element is still flagged as unsaved.
        if (this.element.unsaved && !this.advancedEdit) {
          let dataname = value
              .toLowerCase()
              .replace(/\s/g, '_') // Replace spaces with underscores
              .replace(/[^\w]/g, "") // Remove all other characters that are not letters or numbers. \w is the same as [A-Za-z0-9_]
          // Get a unique dataname
          dataname = this.getUniqueDataname(dataname)
          // Set dataname
          this.element.name = dataname
        }
      },
      flush: 'post'
    },
    'element.viewMode': {
      deep: true,
      handler(value) {
        // Get element wrapper class
        let wrapperClass = this.element['wrapper-class'];
        let classes = []
        // Get current wrapper-classes as array
        if (typeof wrapperClass === 'string' || wrapperClass instanceof String) {
          classes = wrapperClass.split(" ")
        }
        else if (Array.isArray(wrapperClass)) {
          classes = wrapperClass;
        }
        // First, remove all 'viewMode' classes form the array
        classes = classes.filter(item => item !== 'eform-viewmode-horizontal')
        classes = classes.filter(item => item !== 'eform-viewmode-vertical')
        // Secondly, add currently selected viewMode class (horizontal or vertical)
        classes.push('eform-viewmode-'+value);
        // Set classes
        this.element['wrapper-class'] = classes;
      }
    },
  },
  data () {
    return {
      EformElementEditValues: {},
      modal: false,
      element: this.item,
      validation: { // This holds variables that are bound to the form values
        required: false,
      },
      validationRules: [], // This array will set to element.validation
      advancedEdit: false,
      formErrors: [],
      inputErrors: {},
      datanameIsUnique: true,
    }
  },
  props: {
    item: Object,
    form: Object,
    disabledProp: Boolean,
  },
  mounted() {
    // Get current validation and save them in this.validationRules
    this.validationRules = this.elementValidation;

    // Check if required rule is set
    let validationRuleIndex = this.getValidationRuleIndex('required');
    if (validationRuleIndex !== false) {
      this.validation.required = true;
    }
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    closeModal() {
      if (this.isUploading) {
        this.$toast.error("Er wordt nog een bestand of afbeelding geupload. Wacht totdat deze klaar is met uploaden en sluit het venster opnieuw.");
        return false
      }
      this.saveElement();
      this.modal = false;
    },
    addValidationRule(ruleName, value = undefined, applyTo = undefined) {
      // Check if validation rule is not set yet
      let validationRuleIndex = this.getValidationRuleIndex(ruleName);
      if (validationRuleIndex === false) {
        // Build validation rule
        let validationRule = [ruleName];
        // Most rules only have a ruleName. Example: ['required])
        // But some rules also need a value. Example: ['min','5'] and ['max','10']
        if (value !== undefined) {
          validationRule.push(value);
        }

        // Some rules have both a value and a specific option it applies to. Example: ['min', '5', 'length'])
        if (applyTo !== undefined) {
          validationRule.push(applyTo);
        }
        // Add validation rule
        this.validationRules.push(validationRule);
      }
    },
    removeValidationRule(ruleName) {
      // Check if validation rule is not set yet
      let validationRuleIndex = this.getValidationRuleIndex(ruleName);
      if (validationRuleIndex !== false) {
        // Remove validation rule value from array
        this.validationRules.splice(validationRuleIndex, 1);
      }
    },
    addOrUpdateValidationRule(args) {
      let ruleName = args.ruleName;
      let value = args.value;
      let applyTo = args.applyTo;

      // Check if validation rule already exists.
      let validationRuleIndex = this.getValidationRuleIndex(ruleName);
      if (validationRuleIndex !== false) {
        // Update value of the existing validation rule
        this.validationRules[validationRuleIndex][1] = value;
        if (applyTo) {
          this.validationRules[validationRuleIndex][2] = applyTo;
        }
      }
      else {
        // Create new validation rule
        this.addValidationRule(ruleName, value, applyTo);
      }
    },
    getValidationRuleIndex(ruleName) {
      // Returns the validation index when it exists. Returns FALSE when the ruleName does not exist.
      let length = this.validationRules.length;
      for (let i = 0; i < length; i++) {
        if (this.validationRules[i][0] === ruleName) {
          return i;
        }
      }
      return false;
    },
    getUniqueDataname(dataname) {
      // Get a list of all datanames
      let datanames = this.getDatanames(this.form)
      // Check if dataname exits in current form
      const isFound = this.datanameExists(datanames, dataname)

      if (isFound) {
        // Dataname already exists. Increment dataname number until we have a unique filename.
        let datanameNumber = 1
        // Check if incremented dataname exists in form
        while (this.datanameExists(datanames, dataname+datanameNumber)) {
          datanameNumber++
        }
        return dataname+datanameNumber
      }
      else {
        // Return the dataname unchanged.
        return dataname
      }
    },
    datanameExists(datanames, dataname) {
      const isFound = datanames.some(element => {
        if (element.dataname === dataname) {
          return true;
        }
      });
      return isFound
    },
    saveElement() {
      // Check if user has checked 'required'
      if (this.validation.required) {
        this.addValidationRule('required');
        this.removeValidationRule('optional');
      }
      else {
        // Remove 'required' value from array
        this.removeValidationRule('required');
        this.addValidationRule('optional');
      }

      // Add validationRules to the element schema
      this.element.validation = this.validationRules;

      // Bugfix: Convert the Vue Formulate complex file upload inside repeatable groups into simple data.
      if (this.element.imageUpload) {
        let imageUploads = this.convertComplexFileData(this.element.imageUpload)
        this.element.image = imageUploads[0]
        // Remove all imageUpload data because this prevents us from saving the form
        delete this.element.imageUpload
      }
      // Bugfix: Convert the Vue Formulate complex file upload inside repeatable groups into simple data.
      if (this.element.fileUpload) {
        let fileUploads = this.convertComplexFileData(this.element.fileUpload)
        this.element.file = fileUploads[0]
        // Remove all fileUpload data because this prevents us from saving the form
        delete this.element.fileUpload
      }

      // Save element
      // this.$store.commit('updateElement', {form_id: this.form_id, element: cloneDeep(this.element)});
    },
    addToFavorites() {
      let favoriteName = prompt('Onder welke naam wilt u dit element in uw favorieten opslaan?', 'Favoriet ' + this.element.label);

      if (favoriteName == null || favoriteName === "") {
        this.$toast.error("Favoriet is niet opgeslagen, u moet een naam opgeven");
      }
      else {
        const newFavorite = _.cloneDeep(this.element);
        newFavorite.favoriteName = favoriteName;

        // Opslaan ofzo
        this.$store.dispatch('addFavoriteElement', {newFavorite: newFavorite});

        // Do backend stuffz

        this.$toast.success('We hebben dit element opgeslagen in uw favorieten')
      }
      console.log(this.element);
    },
    datanameChanged(context) {
      // Get value
      let value = context.model;
      // Check if value is unique
      if (this.uniqueDataname(value) === false) {
        this.datanameIsUnique = false;
        // Show validaton error
        this.$toast.warning("Datanaam is niet uniek.");
      }
      else {
        this.datanameIsUnique = true;
      }
    },
    uniqueDataname(value) {
      let datanamesAsArray = this.getDatanamesAsArray();
      let valueCount = datanamesAsArray.filter((v) => (v === value)).length;
      // Check if the form dataname element exists 2 of more times.
      if (valueCount > 1) {
        return false;
      }
      return true;
    },
    getDatanamesAsArray() {
      let datanamesAsArray = []
      // Get all datanames
      let datanames = this.getDatanames(this.form)
      // Loop over all elements in the form schema
      for (let i in datanames) {
        // Add all elements
        datanamesAsArray.push(datanames[i].dataname)
      }
      // Join with comma
      return datanamesAsArray
    }
  },
}
</script>

<style lang="scss" scoped>
.widget-wrapper {
  //border-bottom: 1px solid #eee;
  position: relative;

  .widget-actions-prepend {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
  }
  .widget-form-element {
    padding-left: 70px;
    padding-right: 60px;
  }
  .widget-actions-append {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
  }
}
</style>
